$(document).ready(function () {
    // if validator then init validation
    if ($.validator && $('.validator').length) {
        validator();
    }
});

/**
 * init validator plugin
 *
 * @return boolean
 */
function validator() {
    console.log('validator');

    // unbind validator events
    $('.validator').unbind().find('.submit').click(function (e) {
        e.preventDefault();  //prevent form from submitting
        var $form = $(this).closest('form.validator');
        //use on if jQuery 1.7+
        console.log('[jquery.validator] catch submit', $form);
        if (!$form.validator.fails()) {
            console.log('[jquery.validator] submit');
            $form.submit();
        }
    });

    // init validator
    $('.validator').each(function (index, item) {
        $(item).validator(
            {
                onBlur    : true,
                oneByInput: true,

                onError: function (item, rule) {
                    console.log('[VALIDATOR] ERROR Rule', item, rule);
                    $('#' + item).parent().addClass('has-error').removeClass('has-success');
                },

                onPassed: function (item, rule) {
                    console.log('[Validator] SUCCESS Rule', item, rule);
                    $('#' + item).parent().addClass('has-success').removeClass('has-error');
                },

                onSuccess: function (item, rule) {
                    console.log('[Validator] onComplete', item, rule);
                }
            });
    });

    return true;
}
