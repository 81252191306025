(function () {
    "use strict";

    var i18n = {
        'es': {
            locale    : {
                daysOfWeek      : ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                monthNames      : ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                firstDay        : 1,
                applyLabel      : 'Aplicar',
                cancelLabel     : 'Buscar',
                fromLabel       : 'Desde',
                toLabel         : 'Hasta',
                customRangeLabel: 'Personalizado',
                selectRange     : 'Selecionar Rango'
            },
            format    : 'DD/MM/YYYY',
            formatText: 'D [de] MMMM [de] YYYY',
            separator : ' a ',
            ranges    : {
                'Hoy'            : [moment(), moment()],
                'Ayer'           : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Últimos 7 días' : [moment().subtract(6, 'days'), moment()],
                'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
                'Este mes'       : [moment().startOf('month'), moment().endOf('month')],
                'Último mes'     : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
        },
        'en': {
            locale    : {
                daysOfWeek      : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames      : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay        : 7,
                applyLabel      : 'Submit',
                cancelLabel     : 'Clear',
                fromLabel       : 'From',
                toLabel         : 'To',
                customRangeLabel: 'Custom',
                selectRange     : 'Select Range'
            },
            format    : 'MM/DD/YYYY',
            formatText: 'MMMM D, YYYY',
            separator : ' to ',
            ranges    : {
                'Today'       : [moment(), moment()],
                'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }
    };

    var lang = $('html').attr('lang');
    i18n     = i18n[lang] ? i18n[lang] : i18n['en'];

    moment.locale(lang);

    var daterange_settings = $.extend(i18n, {
        startDate      : moment().subtract(29, 'days'),
        endDate        : moment(),
        showDropdowns  : true,
        showWeekNumbers: true,
        timePicker     : false,
        opens          : 'right',
        buttonClasses  : ['btn btn-default'],
        applyClass     : 'btn-small btn-primary',
        cancelClass    : 'btn-small',
    });

    $(document).ready(function () {
        if ($('.select_all').length) {
            $('.select_all').click(function (e) {
                if ($('.select_all').is(":checked")) {
                    $('.selectable').prop('checked', true);
                } else {
                    $('.selectable').prop('checked', false);
                }
            });
        }

        // Initialize jquery.validator for forms
        if ($.validator) {
            validator();
        }

        if ($('.daterangepicker').length) {
            initDateRangePickers();
        }

        // Initialize jquery.validator for forms
        if ($('.tinymce').length && tinymce) {
            editor();
        }

        $('.validator .submit').click(function () {

            console.log('Validate to submit');
            if (!$(this).closest('form').validator.fails()) {
                $(this).closest('form').submit();
            }
        });

        $('.validator .submit_back').click(function () {
            $('#form').attr('action', $('#form').attr('action') + '_back')
            $(this).closest('form').submit();
        });

        $('.validator .submit_back2').click(function () {
            $('#form').attr('action', $('#form').attr('action') + '_back2')
            $(this).closest('form').submit();
        });


        /**
         * guarda los elementos del gridStack para que desde el php
         *  se puedan recuperar.
         */
        $('.design .submit').click(function () {
            console.log('Validate to submit');
             if (!$(this).closest('form').validator.fails()) {
                console.log('Save all the elements');
                grid.saveElements();
                $(this).closest('form').submit();
             }

        });

        $('.designUpdate .submit').click(function () {
            console.log('Updated all the elements');
            grid.updateElements();
            $(this).closest('form').submit();
        });

        /*
         * add bind for input with datalist populate from server-side
         */
        $(".datalist.server").on("input", function (e) {
            var value    = $(this).val();
            var url      = $(this).data('url');
            var dataList = $(this).attr('list');
            var begin    = $(this).data('begin') || 1;

            if (value === "" || !url)
                return;

            var $dataList = $('#' + dataList);
            var data      = $dataList.data();

            data.filter = value;

            // Only return values after this characters length to limit results
            if (value.length < begin)
                return;

            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : url,
                data    : data,
                success : function (data) {
                    $dataList.empty();
                    if (data.length) {
                        data.forEach(function (item) {
                            var opt = $("<option>" + item.value + "</option>").attr("value", item.value);
                            delete item.value;
                            for (var property in item) {
                                if (item.hasOwnProperty(property)) {
                                    opt.data(property, item[property]);
                                }
                            }
                            $dataList.append(opt);
                        });
                    }
                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[DataList][Server] ERROR', url, jqXHR);
                }
            });
        });
    });

    /**
     * init validator plugin
     *
     * @return boolean
     */
    function validator() {
        console.log("Run validator");

        // unbind validator events
        $('form.validate').unbind();

        // init validator
        $('form.validate').validator(
            {
                onBlur    : true,
                oneByInput: true,

                onError: function (item, rule) {
                    console.log('[VALIDATOR] ERROR Rule', item, rule);
                    $('#' + item).closest('.form-group').addClass('has-error').removeClass('has-success');
                },

                onPassed: function (item, rule) {
                    console.log('[Validator] SUCCESS Rule', item, rule);
                    $('#' + item).closest('.form-group').addClass('has-success').removeClass('has-error');
                },

                onSuccess: function (item, rule) {
                    console.log('[Validator] onComplete', item, rule);
                }
            });

        return true;
    }

    /**
     * init tinymce editor on textareas with tinymce class
     */
    function editor() {
        tinymce.init({
            selector    : 'textarea.tinymce',
            height      : 500,
            theme       : 'modern',
            plugins     : [
                'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                'searchreplace wordcount visualblocks visualchars code fullscreen',
                'insertdatetime media nonbreaking save table contextmenu directionality',
                'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help'
            ],
            toolbar1    : 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
            toolbar2    : 'print preview media | forecolor backcolor emoticons | codesample help',
            image_advtab: true,
            templates   : [
                {title: 'Test template 1', content: 'Test 1'},
                {title: 'Test template 2', content: 'Test 2'}
            ],
            content_css : [
                '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
            ]
        });
    }

    function initDateRangePickers() {

        console.log('initDateRangePickers');

        if (typeof ($.fn.daterangepicker) === 'undefined') {
            return;
        }
        //console.log('init_daterangepicker_right');

        $('.daterangepicker').each(function (index, picker) {
            $(picker).initDateRangePicker();
        });
    }

    /**
     *
     */
    $.fn.initDateRangePicker = function () {

        console.log('initDateRangePicker', this);

        var id = $(this).attr('id');

        var $input_start = $('#' + id + '_start');
        var $input_end   = $('#' + id + '_end');
        var $span        = $(this).find('span');

        var cb = function (start, end, label) {
            //console.log(start.toISOString(), end.toISOString(), label);
            //$('#orders_date_filter span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
            $span.html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        };

        // set init daterangepicker status
        //$(this).find('span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY'));

        $(this).daterangepicker(daterange_settings, cb);

        $(this).on('show.daterangepicker', function () {
            console.log("show event fired");
        });

        $(this).on('hide.daterangepicker', function () {
            console.log("hide event fired");
        });

        $(this).on('apply.daterangepicker', function (ev, picker) {
            if (picker.startDate && picker.endDate) {
                // get timestamp values
                $input_start.val(picker.startDate.format('YYYY-MM-DD HH:mm:ss'));
                $input_end.val(picker.endDate.format('YYYY-MM-DD HH:mm:ss'));

                $span.html(picker.startDate.format(i18n.formatText) + ' - ' + picker.endDate.format(i18n.formatText));

                server_datatable.draw();
            }
        });


        $(this).on('cancel.daterangepicker', function (ev, picker) {
            // clear
            $input_start.val('');
            $input_end.val('');

            $span.html(i18n.locale.selectRange);

            server_datatable.draw();
        });
    }


}());