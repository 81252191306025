/**
 * loadItems.js
 * Created by PULSARINARA\r.mill on 29/08/18.
 */

(function ($) {
    "use strict";

    $(document).ready(function () {

        var loadItems = function (item) {
            let data = {
                item_id: item.val(),
            } ;

            if ($('#view').val() === 'edit'){
                data['edit'] = $('#id').val();
            }
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url'),
                data    : data,
                success : function (data) {
                    $.each(data, function (name) {
                        if ($("#" + name).length) {

                            $("#" + name).find('option').remove();
                            $.each(this, function (i, e) {
                                if (e.selected == 'true'){
                                    $("#" + name).append('<option value="' + e.uid + '" selected>' + e.name + '</option>');
                                }else{
                                    $("#" + name).append('<option value="' + e.uid + '">' + e.name + '</option>');
                                }
                            });

                            $("#" + name).change();
                        }
                    });

                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR', jqXHR);

                },
                complete: function (data) {
                    setTimeout(() => {
                        $('.selectpicker').selectpicker('refresh');
                        $('.selectpicker').selectpicker('render');
                    }, 200);
                },

            })

        };

        if ($('.select_item').length) {
            $('.select_item').on('change', function () {
                loadItems($(this));
            })

            /*
            * Función utilizada para los edit, para no sobreescribir los datos que ya existen
            * Añandir lo siguiente en el 'editView' dentro del form:
            *   <input type="hidden" id="view" value="edit">
            * Utilizado en:
            * simpleprogram.js
            * loadItems.js
            * */
            if ($('#view').val() !== 'edit') {
                $('.select_item').change();
            }
        }
    });
})
(jQuery);
