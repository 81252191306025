var pulsar = {};
/*
 * extends ajax behavior to include alerts handle automatically
 *
 * data & url must be defined always!
 */
pulsar.ajax = function(customs) {
    console.log('options', customs);

    // build default for ajax
    var options = {
        type      : 'POST',
        dataType  : 'json',
        beforeSend: function () {
            console.log('[AJAX] beforeSend!');

            if (customs.beforeSend) {
                customs.beforeSend();
            }
        },
        success: function(response, textStatus, jqXHR) {  //hijack the success handler
            console.log('[AJAX] success!', response);

            if (customs.success) {
                customs.success(response, textStatus, jqXHR);
            }

            // show alerts
            if (response.message) {
                if (response.result) {
                    //alertify.success(response.message);
                } else {
                    //alertify.error(response.message);
                }
            }
        },
        error     : function (jqXHR, textStatus, errorThrown) {
            console.log('[AJAX] ERROR', jqXHR, textStatus, errorThrown);

            if (customs.error) {
                customs.error(jqXHR, textStatus, errorThrown);
            }

            //alertify.error('Something was wrong!');
        }
    };

    // add url from customs
    options.url  = customs.url;

    // add data from customs
    if (customs.data) {
        options.data = customs.data;
    }

    //send request
    return $.ajax(options);
};