/**
 * sector.js
 * Created by PULSARINARA\d.monfort on 14/02/22.
 */

(function ($) {
    "use strict";
    $(document).ready(function () {

        var loadSequences = function (item) {
            console.log(item.val());
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-element'),
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {
                    if ($(".sector-elements").length) {

                        $(".sector-elements").find('option').remove();
                        let elements = data["elements"];
                        $.each(elements, function (name) {
                            // console.log(actuators[name]);
                            let element = elements[name];
                            $(".sector-elements").append('<option value="' + element["uid"] + '">' + element["name"]+ '</option>');

                        });
                    }
                    if ($(".sector-actuators").length) {

                        $(".sector-actuators").find('option').remove();
                        let actuators = data["actuators"];
                        $.each(actuators, function (name) {
                            // console.log(actuators[name]);
                            let actuator = actuators[name];
                            $(".sector-actuators").append('<option value="' + actuator["uid"] + '">' + actuator["name"]+ '</option>');

                        });
                    }

                    //sector-elements

                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR secotr.js -> url-element', jqXHR);

                },
                complete : function (data) {
                    setTimeout(() => {
                        $('.selectpicker').selectpicker('refresh');
                        $('.selectpicker').selectpicker('render');
                    }, 200);
                },
            });
        };

        if ($('.user-sector').length) {
            $('.user-sector').on('change', function () {

                loadSequences($(this));
            });

            $('.user-sector').change();
        }
    });

})(jQuery);

