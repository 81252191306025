
$(document).ready(function () {
    init_datepicker_chulo();
});

function init_datepicker_chulo() {
    $('input[name="liveDateTime"]').daterangepicker({
        timePicker         : true,
        singleDatePicker: true,
        singleClasses: "picker_1",
        locale             : {
            format: 'DD/MM/YYYY h:mm A'
        }
    });
}
