
$(document).ready(function () {
    init_chart_info_doughnut();
});


function init_chart_info_doughnut() {
    if ($('.pulsar_chart')) {
        if (typeof (Chart) === 'undefined') {
            console.log('Doughnut Error: Chart undefined.');
            return;
        }

        c = 0;
        $('.pulsar_chart').each(function () {
            var url      = $(this).data('url');
            var id       = 'canvasInfoDoughnut' + c;
            headerLabels = $(this).data('headerlabels');
            headerValues = $(this).data('headervalues');
            console.log('Bucle: ' + id);

            // Generate and put: base structure for canvas and data table
            $(this).html(
                genHTMLBase(headerLabels, headerValues, url, id)
            );

            var colours = [
                "#9B59B6",
                "#E74C3C",
                "#26B99A",
                "#BDC3C7",
                "#3498DB",
                "#9F0096",
                "#9FFFFF",
                "#DE0600",
                "#DEFF00",
                "#001DEB",
                "#E891FF",
                "#442A4A",
                "#44F74A",
                "#FF9953",
                "#945831",
                "#D6E884",
                "#100094"
            ];

            // Get the data
            $.ajax({
                type    : 'POST',
                url     : url,
                dataType: 'json',
                cache   : false,
                success : function (data) {
                    if (data) {
                        html         = '';
                        values_array = [];
                        labels_array = [];

                        // Generate and put data contents in data-table-structure previously generated
                        $('#' + id + '_table').html(
                            genDataTable(data, colours)
                        );

                        console.log('Ajax: ' + id);
                        console.log(data);

                        // Construct data structure for canvas and create a new instance for graph
                        runDoughnut(id, labels_array, values_array, colours);
                    }
                },
                error   : function (xhr, status, error) {
                    console.log('Error Ajax Donut');
                }
            });

            c++;
        });

        /**
         * Generate the base structure for canvas and data table
         * @param headerLabels
         * @param headerValues
         * @param url
         * @param id
         * @returns {string}
         */
        function genHTMLBase(headerLabels, headerValues, url, id) {
            return '<table class="" style="width:100%">' +
                '<tr>' +
                '<th style="width:37%;"></th>' +
                '<th>' +
                '<div class="col-lg-8 col-md-8 col-sm-7 col-xs-7">' +
                '<p class="">' + headerLabels + '</p>' +
                '</div>' +
                '<div class="col-lg-4 col-md-4 col-sm-5 col-xs-5">' +
                '<p class="">' + headerValues + '</p>' +
                '</div>' +
                '</th>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<canvas id="' + id + '" class="canvasInfoDoughnut" height="140" width="140"' +
                'style="margin: 15px 10px 10px 0" data-url="' + url + '"></canvas>' +
                '</td>' +
                '<td colspan="2" style="vertical-align: top">' +
                '<table class="tile_info" id="' + id + '_table"></table>' +
                '</td>' +
                '</tr>' +
                '</table>';
        }


        /**
         * Generate the data contents for data-table-structure
         * @param data
         * @returns {string}
         */
        function genDataTable(data, colours) {
            html  = '';
            var c = 0;
            $.each(data, function (index, element) {
                var val = '';

                if (element.percentage) {
                    val = element.value + ' (' + element.percentage + '%)';
                } else {
                    val = element.value;
                }
                html = html + '<tr><td><p><i class="fa fa-square blue" style="color: ' + colours[c] + '"></i>' + element.label +
                    '</p></td><td style="min-width: inherit">' + val + '</td></tr>';

                labels_array.push(element.label);
                values_array.push(element.value);
                c++;
            });
            return html;
        }

        /**
         * Construct data structure for canvas and create a new instance for graph
         * @param id
         * @param labels
         * @param values
         */
        function runDoughnut(id, labels, values, colours) {
            if ($('#' + id).length) {
                var chart_doughnut_settings = {
                    type            : 'doughnut',
                    tooltipFillColor: "rgba(51, 51, 51, 0.55)",
                    data            : {
                        labels  : labels,
                        datasets: [{
                            data                : values,
                            backgroundColor     : colours,
                            hoverBackgroundColor: [
                                "#B370CF",
                                "#E95E4F",
                                "#36CAAB",
                                "#CFD4D8",
                                "#49A9EA",
                                "#9F0096",
                                "#9FFFFF",
                                "#DE0600",
                                "#DEFF00",
                                "#001DEB",
                                "#E891FF",
                                "#442A4A",
                                "#44F74A",
                                "#FF9953",
                                "#945831",
                                "#D6E884",
                                "#100094"
                            ]
                        }]
                    },
                    options         : {
                        legend    : false,
                        responsive: false
                    }
                }

                $('#' + id).each(function () {
                    console.log('New Chart: #' + id);
                    var chart_element  = $(this);
                    var chart_doughnut = [];
                    chart_doughnut.push(new Chart(chart_element, chart_doughnut_settings));
                });

            }
        }
    }
}
