/**
 * elementConditions.js
 * Created by PULSARINARA\d.monfort on 4/03/22.
 */
(function ($) {
    "use strict";
    $(document).ready(function () {

        if ($('.load-elements').length) {
            $('.load-elements').change(function () {
                // console.log($(this));
                // loadConditions($(this));

            })
        }
    });
})(jQuery);