/**
 * nodeChart.js
 * Created by AGROINTELLIGENT\d.monfort on 1/04/22.
 */

function init_charts_statistics() {


    if ($('#nodeLineChart').length) {

        // Line chart
        let labels = [];

        let dataH = [];
        let dataQ = [];
        let dataP = [];


        for (let i = 1; i < 30; i++) {

            labels.push(i);
            for (let j = 0; j < 24; j++) {
                labels.push("");
            }
        }

        let qInicial = 7.20;
        let pInicial = 5.90;
        let q = 0.00250348;
        let p = 0.00205146;
        let numero = 1;
        let numeros = [7,
            8,
            9,
            10,
            11,
            12,
            13,
            17,
            18,
            19,
            20,
            21,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            41,
            42,
            43,
            44,
            45,
            56,
            57,
            58,
            59,
            60,
            61,
            66,
            67,
            68,
            69,
            80,
            81,
            82,
            83,
            84,
            85,
            86,
            90,
            91,
            92,
            93,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            114,
            115,
            116,
            117,
            128,
            129,
            130,
            131,
            132,
            133,
            134,
            138,
            139,
            140,
            141,
            152,
            153,
            154,
            155,
            156,
            157,
            158,
            162,
            163,
            164,
            165,
            176,
            177,
            178,
            179,
            180,
            181,
            182,
            186,
            187,
            188,
            189,
            200,
            201,
            202,
            203,
            204,
            205,
            206,
            210,
            211,
            212,
            213,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            234,
            235,
            236,
            237,
            248,
            249,
            250,
            251,
            252,
            253,
            254,
            258,
            259,
            260,
            261,
            272,
            273,
            274,
            275,
            276,
            277,
            278,
            282,
            283,
            284,
            285,
            296,
            297,
            298,
            299,
            300,
            301,
            306,
            307,
            308,
            309,
            320,
            321,
            322,
            323,
            324,
            325,
            326,
            330,
            331,
            332,
            333,
            344,
            345,
            346,
            347,
            348,
            349,
            350,
            354,
            355,
            356,
            357,
            368,
            369,
            370,
            371,
            372,
            373,
            374,
            378,
            379,
            380,
            381,
            392,
            393,
            394,
            395,
            396,
            397,
            398,
            402,
            403,
            404,
            405,
            416,
            417,
            418,
            419,
            420,
            421,
            422,
            426,
            427,
            428,
            429,
            440,
            441,
            442,
            443,
            444,
            445,
            446,
            450,
            451,
            452,
            453,
            464,
            465,
            466,
            467,
            468,
            469,
            470,
            474,
            475,
            476,
            477,
            488,
            489,
            490,
            491,
            492,
            493,
            494,
            498,
            499,
            500,
            501,
            512,
            513,
            514,
            515,
            516,
            517,
            518,
            522,
            523,
            524,
            525,
            536,
            537,
            538,
            539,
            540,
            541,
            542,
            546,
            547,
            548,
            549,
            560,
            561,
            562,
            563,
            564,
            565,
            566,
            570,
            571,
            572,
            573,
            584,
            585,
            586,
            587,
            588,
            589,
            590,
            594,
            595,
            596,
            597,
            608,
            609,
            610,
            611,
            612,
            613,
            614,
            618,
            619,
            620,
            621,
            632,
            633,
            634,
            635,
            636,
            637,
            638,
            642,
            643,
            644,
            645,
            656,
            657,
            658,
            659,
            660,
            661,
            662,
            666,
            667,
            668,
            669,
            680,
            681,
            682,
            683,
            684,
            685,
            686,
            690,
            691,
            692,
            693,
            704,
            705,
            706,
            707,
            708,
            709,
            710,
            714,
            715,
            716,
            717,
        ];



        labels.forEach(function () {
            dataH.push(9.9);

            if (numeros.includes(numero)) {
                dataQ.push(0);
                dataP.push(0);
            } else {
                dataQ.push(qInicial - (numero * q));
                dataP.push(pInicial - (numero * p));
            }
            numero++;
        });

        $('.nodeLineChart').each(function () {

            let ctx = document.getElementById("nodeLineChart").getContext('2d');
            let config1 = {
                type: 'line',
                data: {
                    labels  : labels,
                    datasets: [{
                        label                    : "Profundidad (m)",
                        backgroundColor          : "rgba(136, 136, 136, 0.31)",
                        borderColor              : "rgba(136, 136, 136, 0.7)",
                        pointBorderColor         : "rgba(136, 136, 136, 0.7)",
                        pointBackgroundColor     : "rgba(136, 136, 136, 0.7)",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor    : "rgba(220,220,220,1)",
                        fill                     : false,
                        tension                  : 0.3,
                        stepped                  : true,
                        pointBorderWidth         : 1,
                        data                     : dataH
                    }, {
                        label                    : "Caudal (m³/h)",
                        backgroundColor          : "rgba(38, 185, 154, 0.21)",
                        borderColor              : "rgba(38, 185, 154, 0.4)",
                        pointBorderColor         : "rgba(38, 185, 154, 0.4)",
                        pointBackgroundColor     : "rgba(38, 185, 154, 0.4)",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor    : "rgba(220,220,220,1)",
                        pointBorderWidth         : 0.5,
                        // fill: false,
                        tension                  : 0,
                        stepped                  : 'middle',
                        data                     : dataQ
                    }, {
                        label                    : "Presión (bar)",
                        backgroundColor          : "rgba(3, 88, 106, 0.3)",
                        borderColor              : "rgba(3, 88, 106, 0.70)",
                        pointBorderColor         : "rgba(3, 88, 106, 0.70)",
                        pointBackgroundColor     : "rgba(3, 88, 106, 0.70)",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor    : "rgba(151,187,205,1)",
                        pointBorderWidth         : 1,
                        tension                  : 0,
                        // fill: false,
                        stepped                  : true,
                        data                     : dataP
                    }]
                },
                options:{
                    scales: {
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Metros'
                            }
                        }],
                        xAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Días'
                            }
                        }]
                    },
                }

            };

            let lineChart = new Chart(ctx, config1);

            document.getElementById("nodeLineChart").innerHTML = lineChart.generateLegend();

            lineChart.update();

        })


    }
}

$(document).ready(function () {

    init_charts_statistics();

});

