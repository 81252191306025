/**
 * modalindex.js
 * 2023/03/24
 * d.monfort@agrointelligent.com
 */


(function ($) {

    // $('#deleteItemIndex').modal()   //Llama al modal.


    // function waitForElm(elemen){
    //     return new Promise(resolve => {
    //         if (document.querySelector(elemen)) {
    //             return resolve(document.querySelector(elemen));
    //         }
    //         const observer = new MutationObserver(mutations => {
    //             if (document.querySelector(elemen)) {
    //                 resolve(document.querySelector(elemen));
    //                 observer.disconnect();
    //             }
    //         });
    //
    //         observer.observe(document.body, {
    //             childList: true,
    //             subtree: true
    //         });
    //     });
    // }
    //
    //
    //
    //
    //
    // $(document).ready( function () {
    //
    //     waitForElm('.deleteItemIndex').then((elm) => {
    //         console.log('Element is ready');
    //         let botones = document.getElementsByClassName("deleteItemIndex");
    //         console.log(botones.length);
    //
    //         for (var i = 0; i < botones.length; i++) {
    //             console.log(botones.item(i).id);
    //             $("#"+botones.item(i).id).click(function (element){
    //                 console.log(element.currentTarget);
    //             })
    //         }
    //     });
    //
    //
    //
    //
    //     console.log("modalindex.js");
    //
    //     // let botones = document.getElementsByClassName("deleteItemIndex");
    // });
    //


})(jQuery);