/**
 * sensor.js
 * Created by PULSARINARA\d.monfort on 24/10/22.
 */
(function ($) {
    "use strict";
    $(document).ready(function () {

        if ($('#sensor_view').length) {
            var device_selected = $("#device_type").val();
            var loadFeature = function (item) {
                console.log(item.val());
                $.ajax({
                    type    : "POST",
                    dataType: "json",
                    url     : item.data('url-feature'),
                    data    : {
                        item_id: item.val(),
                    },
                    success : function (data) {
                        console.log(data);
                    },
                    error   : function (jqXHR, textStatus, errorThrown) {
                        console.log('[filterById] ERROR secotr.js -> url-element', jqXHR);
                    }
                });
            };

            if ($('.select-feature').length) {
                $('.select-feature').on('change', function () {
                    loadFeature($(this));
                    device_selected = $("#device_type").val();
                    $("#ad").change();
                });
            }
            if ($('#ad').length){
                $('#ad').on('change', function () {
                    let ad = $(this).val()
                    if (ad === "a" && device_selected !== "6"){
                        $(".options").hide();
                        $(".m_out").show();
                    }else if(ad === "d" && device_selected === "6"){
                        $(".options").hide();
                        $(".pulses").show();
                    }else{
                        $(".options").hide();
                    }
                })
                $("#ad").change();
            }
        }
    });

})(jQuery);
