/**
 * measure.js
 * Created by PULSARINARA\d.monfort on 30/07/21.
 */
//
// (function ($) {
//     "use strict";
//     $(document).ready(function () {
//
//         let loadMeasure = function (item) {
//             $.ajax({
//                 type: "POST",
//                 dataType: "json",
//                 url: item.data('url-options'),
//                 data: {
//                     item_id: item.val(),
//                 },
//                 success: function (data) {
//                     if ($("#measure").length) {
//                         $('#measure').html(data);
//                     }
//
//
//                 },
//                 error: function (jqXHR, textStatus, errorThrown) {
//                     console.log('[filterById] ERROR', jqXHR);
//                 }
//
//             })
//         };
//
//         if ($('.load-elements').length) {
//             $('.load-elements').change(function () {
//
//                 if ($('.sensor').length) {
//                     $('.sensor').on('change', function () {
//                         loadMeasure($(this));
//                     })
//
//                     $('.sensor').change();
//                 }
//
//             })
//         }
//
//
//
//     });
// })(jQuery);


