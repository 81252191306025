/**
 * line.js
 * Created by AGROINTELLIGENT\d.monfort on 24/03/22.
 */
(function ($) {
    "use strict";


    function init_charts_intensities(null_values,on_change = false) {
        //line chart

        if ($('.lineChartIntensities').length) {

            $('.lineChartIntensities').each(function () {
                let url = $(this).data('url-intensities');
                let val1 = $('#intensityInput1').val();
                let val2 = $('#intensityInput2').val();
                let val3 = $('#intensityInput3').val();
                $.ajax({
                    type    : 'POST',
                    dataType: 'json',
                    url     : url,
                    cache   : false,
                    data    : {
                        null_values: null_values,
                        val1: val1,
                        val2: val2,
                        val3: val3
                    },
                    success : function (data) {

                        if (data) {
                            let labels = data.times;
                            let i1 = data.i1;
                            let i2 = data.i2;
                            let i3 = data.i3;

                            if (on_change) {
                                let pos = url.lastIndexOf("/");
                                let id = url.substring(pos + 1);
                                let chart = document.getElementById('contentChartIntensities');
                                chart.innerHTML = '';
                                $('#contentChartIntensities').append('<canvas class="lineChartIntensities" id="lineChartIntensities" data-url-intensities="/sensor/an/anIntensities/' + id + '"></canvas>');
                            }


                            let ctx = document.getElementById("lineChartIntensities");
                            let new_data = {
                                type   : 'line',
                                data   : {
                                    labels  : labels,
                                    datasets: [{
                                        label                    : "I1",
                                        data                     : i1,
                                        backgroundColor          : "rgba(38, 185, 154, 0.31)",
                                        borderColor              : "rgba(38, 185, 154, 0.7)",
                                        pointBorderColor         : "rgba(38, 185, 154, 0.7)",
                                        pointBackgroundColor     : "rgba(38, 185, 154, 0.7)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(220,220,220,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,

                                    }, {
                                        label                    : "I2",
                                        data                     : i2,
                                        backgroundColor          : "rgba(3, 88, 106, 0.3)",
                                        borderColor              : "rgba(3, 88, 106, 0.70)",
                                        pointBorderColor         : "rgba(3, 88, 106, 0.70)",
                                        pointBackgroundColor     : "rgba(3, 88, 106, 0.70)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(151,187,205,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,
                                    }, {
                                        label                    : "I3",
                                        data                     : i3,
                                        backgroundColor          : "rgba(80, 173, 30, 0.3)",
                                        borderColor              : "rgba(80, 173, 30, 0.70)",
                                        pointBorderColor         : "rgba(80, 173, 30, 0.70)",
                                        pointBackgroundColor     : "rgba(80, 173, 30, 0.70)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(151,187,205,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,
                                    }]
                                },
                                options: {

                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                // beginAtZero: true,
                                                suggestedMin: 0,
                                                suggestedMax: 1,
                                            }
                                        }]
                                    }
                                    // scales: {
                                    //     yAxes: [{
                                    //         scaleLabel: {
                                    //             display: true,
                                    //             labelString: 'Amperios'
                                    //         }
                                    //     }]
                                    // },


                                },
                            };


                            let lineChart1 = new Chart(ctx, new_data);
                        }
                    },
                    error   : function (xhr, status, error) {
                        console.log('Error Line Chart, Intensities ');
                    }
                });

            })


        }
    }

    function init_charts_voltages(null_values,on_change = false) {

        if ($('.lineChartVoltages').length) {


            $('.lineChartVoltages').each(function () {
                let url2 = $(this).data('url-voltages');

                let val1 = $('#voltageInput1').val();
                let val2 = $('#voltageInput2').val();
                let val3 = $('#voltageInput3').val();
                $.ajax({
                    type    : 'POST',
                    dataType: 'json',
                    url     : url2,
                    cache   : false,
                    data    : {
                        null_values: null_values,
                        val1: val1,
                        val2: val2,
                        val3: val3
                    },
                    success : function (data) {

                        if (data) {
                            let labels = data.times;
                            let i1 = data.i1;
                            let i2 = data.i2;
                            let i3 = data.i3;

                            if (on_change) {
                                let pos = url2.lastIndexOf("/");
                                let id = url2.substring(pos + 1);
                                let chart = document.getElementById('contentChartVoltages');
                                chart.innerHTML = '';
                                $('#contentChartVoltages').append('<canvas class="lineChartVoltages" id="lineChartVoltages" data-url-voltages="/sensor/an/anVoltages/' + id + '"></canvas>');
                            }

                            let ctx = document.getElementById("lineChartVoltages");
                            let lineChart2 = new Chart(ctx, {
                                type: 'line',
                                data: {
                                    labels  : labels,
                                    datasets: [{
                                        label                    : "V1",
                                        data                     : i1,
                                        backgroundColor          : "rgba(38, 185, 154, 0.31)",
                                        borderColor              : "rgba(38, 185, 154, 0.7)",
                                        pointBorderColor         : "rgba(38, 185, 154, 0.7)",
                                        pointBackgroundColor     : "rgba(38, 185, 154, 0.7)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(220,220,220,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,

                                    }, {
                                        label                    : "V2",
                                        data                     : i2,
                                        backgroundColor          : "rgba(3, 88, 106, 0.3)",
                                        borderColor              : "rgba(3, 88, 106, 0.70)",
                                        pointBorderColor         : "rgba(3, 88, 106, 0.70)",
                                        pointBackgroundColor     : "rgba(3, 88, 106, 0.70)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(151,187,205,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,
                                    }, {
                                        label                    : "V3",
                                        data                     : i3,
                                        backgroundColor          : "rgba(80, 173, 30, 0.3)",
                                        borderColor              : "rgba(80, 173, 30, 0.70)",
                                        pointBorderColor         : "rgba(80, 173, 30, 0.70)",
                                        pointBackgroundColor     : "rgba(80, 173, 30, 0.70)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(151,187,205,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,
                                    }]
                                },

                            });
                        }
                    },
                    error   : function (xhr, status, error) {
                        console.log('Error Line Chart Voltages ');
                    }
                });


            })


        }

    }

    function init_charts_values(null_values,on_change = false) {
        if ($('.lineChartValues').length) {


            $('.lineChartValues').each(function () {
                let url3 = $(this).data('url-values');

                let val1 = $('#powerInputP').val();
                let val2 = $('#powerInputQ').val();
                let val3 = $('#powerInputS').val();
                $.ajax({
                    type    : 'POST',
                    dataType: 'json',
                    url     : url3,
                    cache   : false,
                    data    : {
                        null_values: null_values,
                        val1: val1,
                        val2: val2,
                        val3: val3,
                    },
                    success : function (data) {

                        if (data) {
                            let labels = data.times;
                            let i1 = data.i1;
                            let i2 = data.i2;
                            let i3 = data.i3;

                            if (on_change) {
                                let pos = url3.lastIndexOf("/");
                                let id = url3.substring(pos + 1);
                                let chart = document.getElementById('contentChartValues');
                                chart.innerHTML = '';
                                $('#contentChartValues').append('<canvas class="lineChartValues" id="lineChartValues" data-url-values="/sensor/an/anValues/' + id + '"></canvas>');
                            }

                            let ctx = document.getElementById("lineChartValues");
                            let lineChart3 = new Chart(ctx, {
                                type: 'line',
                                data: {
                                    labels  : labels,
                                    datasets: [{
                                        label                    : "P",
                                        data                     : i1,
                                        backgroundColor          : "rgba(38, 185, 154, 0.31)",
                                        borderColor              : "rgba(38, 185, 154, 0.7)",
                                        pointBorderColor         : "rgba(38, 185, 154, 0.7)",
                                        pointBackgroundColor     : "rgba(38, 185, 154, 0.7)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(220,220,220,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,

                                    }, {
                                        label                    : "Q",
                                        data                     : i2,
                                        backgroundColor          : "rgba(3, 88, 106, 0.3)",
                                        borderColor              : "rgba(3, 88, 106, 0.70)",
                                        pointBorderColor         : "rgba(3, 88, 106, 0.70)",
                                        pointBackgroundColor     : "rgba(3, 88, 106, 0.70)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(151,187,205,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,
                                    }, {
                                        label                    : "S",
                                        data                     : i3,
                                        backgroundColor          : "rgba(80, 173, 30, 0.3)",
                                        borderColor              : "rgba(80, 173, 30, 0.70)",
                                        pointBorderColor         : "rgba(80, 173, 30, 0.70)",
                                        pointBackgroundColor     : "rgba(80, 173, 30, 0.70)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(151,187,205,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.3,
                                    }]
                                },
                            });

                            // Convierte la gráfica en una imagen (de bastante poca calidad la verdad)
                            // setTimeout(() => {
                            //     let valor = lineChart3.toBase64Image();
                            //     console.log(valor);
                            // }, 1200);


                        }
                    },
                    error   : function (xhr, status, error) {
                        console.log('Error Line Chart, Power');
                    }
                });

            })


        }

    }

    function init_charts_cos(null_values,on_change = false) {
        if ($('.lineChartCos').length) {
            $('.lineChartCos').each(function () {
                let url4 = $(this).data('url-cos');

                Chart.defaults.global.legend = {
                    // linewidths
                    enabled: true,
                };

                $.ajax({
                    type    : 'POST',
                    dataType: 'json',
                    url     : url4,
                    cache   : false,
                    data    : {
                        null_values: null_values,
                    },
                    success : function (data) {

                        if (data) {
                            let labels = data.times;
                            let i1 = data.i1;


                            if (on_change){
                                let pos = url4.lastIndexOf("/");
                                let id = url4.substring(pos+1);
                                let chart = document.getElementById('contentChartCos');
                                chart.innerHTML = '';
                                $('#contentChartCos').append('<canvas class="lineChartCos" id="lineChartCos" data-url-cos="/sensor/an/anCos/'+id+'"></canvas>');
                            }
                            let ctx = document.getElementById("lineChartCos");
                            let lineChart4 = new Chart(ctx, {
                                type: 'line',
                                data: {
                                    labels  : labels,
                                    datasets: [{

                                        label                    : "FP",
                                        data                     : i1,
                                        backgroundColor          : "rgba(38, 185, 154, 0.31)",
                                        borderColor              : "rgba(38, 185, 154, 0.7)",
                                        pointBorderColor         : "rgba(38, 185, 154, 0.7)",
                                        pointBackgroundColor     : "rgba(38, 185, 154, 0.7)",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor    : "rgba(220,220,220,1)",
                                        pointBorderWidth         : 1,
                                        fill                     : false,
                                        tension                  : 0.6,

                                    }],

                                },
                                options: {
                                    legend:{
                                        // fullWidth: true,
                                        // display: true,
                                    },
                                    // Esto si funciona, si encuentro la leyenda pues se usará esto para mostrar bien el chart
                                    // title:{
                                    //     display:true,
                                    //     text: "prueba",
                                    //     fontSize: 16,
                                    // },
                                    // scales: {
                                    //     yAxes: [{
                                    //         ticks: {
                                    //             // beginAtZero: true,
                                    //             suggestedMin: 0,
                                    //             suggestedMax: 1.1,
                                    //         }
                                    //     }]
                                    // }
                                    // scales: {
                                    //     yAxes: [{
                                    //         scaleLabel: {
                                    //             display: true,
                                    //             labelString: 'Amperios'
                                    //         }
                                    //     }]
                                    // },


                                },
                            });
                            // Chart.defaults.global.legend = {
                            //     enabled: true
                            // };
                            // console.log(lineChart4.legend.options);
                            // console.log(lineChart4.generateLegend());
                            // lineChart4.update(0);
                        }
                    },
                    error   : function (xhr, status, error) {
                        console.log('Error Line Chart Function');
                    }
                });

            })
        }
    }

    function init_charts(null_values, on_change = false) {

        init_charts_cos(null_values, on_change);
        init_charts_values(null_values, on_change);
        init_charts_voltages(null_values, on_change);
        init_charts_intensities(null_values, on_change);
    }

    $(document).ready(function () {
        let nulos = false;


        if ($('#null_values_checkbox').length) {
                init_charts(false);
            $('#null_values_checkbox').change(function () {
                if ($('#null_values_checkbox').prop('checked')) {
                    nulos = true;
                    init_charts(nulos, true);
                } else {
                    nulos = false;
                    init_charts(nulos, true);
                }
            })
        }

        if($('#intensityInput1').length){
            $('#intensityInput1').change(function () {
                init_charts_intensities(nulos, true);
            })
        }

        if($('#intensityInput2').length){
            $('#intensityInput2').change(function () {
                init_charts_intensities(nulos, true);
            })
        }

        if($('#intensityInput3').length){
            $('#intensityInput3').change(function () {
                init_charts_intensities(nulos, true);
            })
        }


        if($('#voltageInput1').length){
            $('#voltageInput1').change(function () {
                init_charts_voltages(nulos, true);
            })
        }

        if($('#voltageInput2').length){
            $('#voltageInput2').change(function () {
                init_charts_voltages(nulos, true);
            })
        }

        if($('#voltageInput3').length){
            $('#voltageInput3').change(function () {
                init_charts_voltages(nulos, true);
            })
        }


        if($('#powerInputP').length){
            $('#powerInputP').change(function () {
                init_charts_values(nulos, true);
            })
        }

        if($('#powerInputQ').length){
            $('#powerInputQ').change(function () {
                init_charts_values(nulos, true);
            })
        }

        if($('#powerInputS').length){
            $('#powerInputS').change(function () {
                init_charts_values(nulos, true);
            })
        }
    });
})(jQuery);



