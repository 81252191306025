/**
 * goups.js
 * Created by PULSARINARA\d.monfort on 25/01/22.
 */

(function ($) {
    "use strict";
    $(document).ready(function () {

        var loadNodes = function (item) {
            // espera la respuesta de ajax por parte del html
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url'),//
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {
                    let n_nodo = 1;
                    $.each(data['nodes'], function (nodo) {


                        let structure = "";

                        structure += "<div class='col-md-6 col-sm-6 col-xs-12'>" +
                            "<input type='checkbox' id='nodo" + n_nodo + "' relation='" + n_nodo + "'>" +
                            "<label for='nodo" + n_nodo + "' >&nbsp;" + nodo + "</label>" +
                            "<div class='list-group'>";

                        $.each(data['nodes'][nodo], function (actuators) {
                            //Lista los actuadores del nodo como
                            // Clave /valor  ->  actuators / data['nodes'][nodo][actuators]


                            structure +=
                                "<label class='list-group-item'>" +
                                "<input class='form-check-input actuators" + n_nodo + "' name='actuators[" + actuators + "]' id='actuators" + actuators + "' type='checkbox' value='1'> " +
                                data['nodes'][nodo][actuators] +
                                "</label>";
                        });

                        structure +=
                            "</div>" +
                            "</div>";
                        $('#nodeList').append(structure);

                        let checkbox = document.getElementById('nodo' + n_nodo);

                        //Se encarga de controlar que si seleccionas un nodo se
                        //  marquen todas las casillas de los actuadores.
                        checkbox.addEventListener('click', function (numero) {

                            let n = numero.srcElement.attributes.relation.value;
                            let box = document.getElementsByClassName('actuators' + n);
                            if (checkbox.checked) {
                                for (let i = 0, n = box.length; i < n; i++) {
                                    box[i].checked = checkbox.checked;
                                }
                            } else {
                                for (let i = 0, n = box.length; i < n; i++) {
                                    box[i].checked = checkbox.checked;
                                }
                            }
                        });

                        n_nodo++;
                    });

                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR groups.js -> url', jqXHR);
                }

            }).then(function () {
                if ($('#edit_group')) {
                    let actuators = [];
                    actuators = JSON.parse($('#edit_group').val());
                    for (let actuator in actuators) {
                        $('#actuators' + actuators[actuator]).attr('checked', true);
                    }
                }
            })
        };

        if ($('.ac-mutator').length) {
            $('.ac-mutator').on('change', function () {
                $('#nodeList').html("");
                loadNodes($(this));
            })

            $('.ac-mutator').change();
        }

    })
})(jQuery);