/*
/!**
 * Created by PULSARINARA\toni.paricio on 28/04/17.
 *!/

var server_datatable,
    client_datatable;

(function ($) {
    $(document).ready(function () {

        if ($('.datatable').length) {

            var language = i18n();

            client_datatable = $('.datatable.client').DataTable({
                ordering      : true,
                stateSave     : true,
                //"info":     false,
                //"bFilter": false,
                autoWidth     : false,
                responsive    : true,
                lengthChange  : true,
                lengthMenu    : [[10, 25, 50, -1], [10, 25, 50, "All"]],
                pageLength    : 10,
                // set language make this.footer() == null on columns.every for some reason
                //language      : {
                // get i18n from datatables CDN
                //    url: language
                //},
                fnInitComplete: function (oSettings, json) {
                    // set save values on input filters
                    var cols = oSettings.aoPreSearchCols;
                    var id   = oSettings.sInstance;
                    if (id && $("#" + id).find("tfoot input").length) {
                        for (var i = 0; i < cols.length; i++) {
                            var value = cols[i].sSearch;

                            if (value.length > 0) {
                                $("#" + id).find("tfoot input")[i].value = value;
                            }
                        }
                    }

                    // Add "Clear Filter" button to Filter
                    var btnClear = $('<a class="btnClearDataTableFilter btn btn-dark btn-sm">Buscar</a>');
                    btnClear.appendTo($('#' + oSettings.sTableId).parents('.dataTables_wrapper').find('.dataTables_filter'));
                    btnClear.click(function () {
                        server_datatable.search('')
                            .columns().search('')
                            .draw();

                    });

                }
            }).columns().every(function () {
                var that = this;

                $('input', this.footer()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });
                $('input', this.header()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();

                    }
                });
            });

            /!**
             * get all datatables server and mutate HTML5 data-ajax from
             * string to valid datatables ajax definition object
             *!/
            $('.datatable.server').each(function (index, data) {
                console.log($(this).data('ajax'), typeof $(this).data('ajax'));
                if (typeof $(this).data('ajax') !== 'object') {
                    var value = {
                        'url' : $(this).data('ajax'),
                        'type': 'POST'
                    }
                    $(this).data('ajax', value);
                }
            });

            server_datatable = $('.datatable.server').DataTable({
                ordering      : true,
                stateSave     : true,
                //"info":     false,
                //"bFilter": false,
                autoWidth     : false,
                responsive    : true,
                lengthChange  : true,
                lengthMenu    : [[10, 25, 50, -1], [10, 25, 50, "All"]],
                pageLength    : 10,
                processing    : true,
                serverSide    : true,
                ajax          : $(this).data('ajax'),
                fnServerParams: function (aoData) {
                    aoData.customs = getCustoms();
                },
                // set language make this.footer() == null on columns.every for some reason
                //language      : {
                // get i18n from datatables CDN
                //     url: language
                //},
                fnInitComplete: function (oSettings, json) {
                    var that = this;
                    // set save values on input filters
                    var cols = oSettings.aoPreSearchCols;
                    var id   = oSettings.sInstance;
                    for (var i = 0; i < cols.length; i++) {
                        var value = cols[i].sSearch;
                        if (value.length > 0) {
                            var $th = $("#" + id).find("tfoot tr th")[i];//.value = value;
                            console.log('th')
                            if ($th.length && $th.find('input').length) {
                                $th.find('input').value = value;
                            }
                            //$("#" + id).find("tfoot input")[i].value = value;

                        }
                    }

                    // Add "Clear Filter" button to Filter
                    var btnClear = $('<a class="btnClearDataTableFilter btn btn-dark btn-sm">Buscar</a>');
                    btnClear.appendTo($('#' + oSettings.sTableId).parents('.dataTables_wrapper').find('.dataTables_filter'));
                    btnClear.click(function () {
                        $('.custom_filters').val('');

                        server_datatable.search('')
                            .columns().search('')
                            .draw();
                    });
                }
            }).columns().every(function (index) {
                var that = this;
                // on server side only update on change, not in keyup, to avoid
                // too many requests
                $('input', this.footer()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });
                $('input', this.header()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });

            });
        }
    });

    var getCustoms = function () {
        var customs = [];

        $('.custom_filters').each(function (index, item) {
            console.log(index, item, $(item).val());
            var custom = {
                'value' : $(item).val(),
                'column': $(item).data('column'),
                'rule'  : $(item).data('rule')
            }
            customs.push(custom);
        });

        return customs;
    }

    /!**
     * get lang from <html> to get datatables i18n from datatables CDN
     * @returns JSON
     *!/
    function i18n() {
        var lang = $('html').attr('lang');

        switch (lang) {
            case 'es' :
                return "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json";
            case 'fr' :
                return "//cdn.datatables.net/plug-ins/1.10.15/i18n/French.json";
            case 'de' :
                return "//cdn.datatables.net/plug-ins/1.10.15/i18n/German.json";
            case 'it' :
                return "//cdn.datatables.net/plug-ins/1.10.15/i18n/Italian.json";
            case 'pt' :
                return "//cdn.datatables.net/plug-ins/1.10.15/i18n/Portuguese.json";
            default:
                return "//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json";
        }
    }

})(jQuery);*/


/**
 * Created by PULSARINARA\toni.paricio on 28/04/17.
 */

var server_datatable,
    client_inform_datatable,
    client_datatable;

(function ($) {
    $(document).ready(function () {

        if ($('.datatable').length) {

            var language = i18n();

            client_inform_datatable = $('.datatable.inform').DataTable({
                ordering: true,
                stateSave: false,
                //"info":     false,
                //"bFilter": false,
                autoWidth: false,
                responsive: true,
                lengthChange: true,
                lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
                pageLength: 10,
                language: language,
                fnInitComplete: function (oSettings, json) {
                    // set save values on input filters
                    var cols = oSettings.aoPreSearchCols;
                    var id = oSettings.sInstance;
                    if (id && $("#" + id).find("tfoot input").length) {
                        for (var i = 0; i < cols.length; i++) {
                            var value = cols[i].sSearch;
                            if (value.length > 0) {
                                $("#" + id).find("tfoot input")[i].value = value;
                            }
                        }
                    }

                    // Add "Clear Filter" button to Filter
                    var btnClear = $('<a class="btnClearDataTableFilter btn btn-dark btn-sm"><i class="fa fa-search"></i></a>');
                    btnClear.appendTo($('#' + oSettings.sTableId).parents('.dataTables_wrapper').find('.dataTables_filter'));
                    btnClear.click(function () {
                        server_datatable.search('')
                            .columns().search('')
                            .draw();
                    });

                }
            });

            client_datatable = $('.datatable.client').DataTable({
                ordering: true,
                stateSave: true,
                //"info":     false,
                //"bFilter": false,
                autoWidth: false,
                responsive: true,
                lengthChange: true,
                lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
                pageLength: 10,
                language: language,
                fnInitComplete: function (oSettings, json) {
                    // set save values on input filters
                    var cols = oSettings.aoPreSearchCols;
                    var id = oSettings.sInstance;
                    if (id && $("#" + id).find("tfoot input").length) {
                        for (var i = 0; i < cols.length; i++) {
                            var value = cols[i].sSearch;
                            if (value.length > 0) {
                                $("#" + id).find("tfoot input")[i].value = value;
                            }
                        }
                    }

                    // Add "Clear Filter" button to Filter
                    var btnClear = $('<a class="btnClearDataTableFilter btn btn-dark btn-sm"><i class="fa fa-search"></i></a>');
                    btnClear.appendTo($('#' + oSettings.sTableId).parents('.dataTables_wrapper').find('.dataTables_filter'));
                    btnClear.click(function () {
                        server_datatable.search('')
                            .columns().search('')
                            .draw();
                    });

                }
            }).columns().every(function () {
                var that = this;
                $('input', this.footer()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });
                $('input', this.header()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });
            });

            /**
             * get all datatables server and mutate HTML5 data-ajax from
             * string to valid datatables ajax definition object
             */
            $('.datatable.server').each(function (index, data) {
                console.log($(this).data('ajax'), typeof $(this).data('ajax'));
                if (typeof $(this).data('ajax') !== 'object') {
                    var value = {
                        'url': $(this).data('ajax'),
                        'type': 'POST'
                    }
                    $(this).data('ajax', value);
                }
            });

            server_datatable = $('.datatable.server').DataTable({
                ordering: true,
                stateSave: true,
                //"info":     false,
                //"bFilter": false,
                autoWidth: false,
                responsive: true,
                lengthChange: true,
                lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
                pageLength: 10,
                processing: true,
                serverSide: true,
                ajax: $(this).data('ajax'),
                language: language,
                fnServerParams: function (aoData) {
                    aoData.customs = getCustoms();
                },
                // set language make this.footer() == null on columns.every for some reason
                //language      : {
                // get i18n from datatables CDN
                //     url: language
                //},
                fnInitComplete: function (oSettings, json) {
                    var that = this;
                    // set save values on input filters
                    var cols = oSettings.aoPreSearchCols;
                    var id = oSettings.sInstance;
                    for (var i = 0; i < cols.length; i++) {
                        var value = cols[i].sSearch;
                        if (value.length > 0) {
                            var $th = $("#" + id).find("tfoot tr th")[i];//.value = value;
                            console.log('th')
                            if ($th.length && $th.find('input').length) {
                                $th.find('input').value = value;
                            }
                            //$("#" + id).find("tfoot input")[i].value = value;
                        }
                    }

                    // Add "Clear Filter" button to Filter
                    var btnClear = $('<a class="btnClearDataTableFilter btn btn-dark btn-sm"><i class="fa fa-search"></i></a>');
                    btnClear.appendTo($('#' + oSettings.sTableId).parents('.dataTables_wrapper').find('.dataTables_filter'));
                    btnClear.click(function () {
                        $('.custom_filters').val('');

                        server_datatable.search('')
                            .columns().search('')
                            .draw();
                    });
                }
            }).columns().every(function (index) {
                var that = this;
                // on server side only update on change, not in keyup, to avoid
                // too many requests
                $('input', this.footer()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });
                $('input', this.header()).on('change', function () {
                    console.log('input change', this, this.value, that);
                    if (that.search() !== this.value) {
                        that.search(this.value).draw();
                    }
                });

            });
        }
    });

    var getCustoms = function () {
        var customs = [];

        $('.custom_filters').each(function (index, item) {
            console.log(index, item, $(item).val());
            var custom = {
                'value': $(item).val(),
                'column': $(item).data('column'),
                'rule': $(item).data('rule')
            }
            customs.push(custom);
        });

        return customs;
    }

    /**
     * result of a get
     * @param url
     * @returns json
     */
    function getResults(url) {
        let result = null;
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            async: false,
            success: function (data) {
                result = data;
            }
        });
        return result;
    }


    /**
     * get lang from <html> to get datatables i18n from datatables CDN
     * @returns JSON
     */
    function i18n() {
        var lang = $('html').attr('lang');
        let result = '';
        switch (lang) {
            case 'es' :
                let es = "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json";
                return getResults(es);
            case 'fr' :
                let fr = "//cdn.datatables.net/plug-ins/1.10.15/i18n/French.json";
                return getResults(fr);

            case 'de' :
                let de = "//cdn.datatables.net/plug-ins/1.10.15/i18n/German.json";
                return getResults(de);

            case 'it' :
                let it = "//cdn.datatables.net/plug-ins/1.10.15/i18n/Italian.json";
                return getResults(it);

            case 'pt' :
                let pt = "//cdn.datatables.net/plug-ins/1.10.15/i18n/Portuguese.json";
                return getResults(pt);

            default:
                let def = "//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json";
                return getResults(def);

        }
    }

})(jQuery);
