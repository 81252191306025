/**
 * element.js
 */
(function ($) {
    "use strict";
    $(document).ready(function () {

        window.arrayActuators = [];

        ////// APAGADO ASISTIDO ////////
        $(".helped-shutdown").click(function () {
            //Elimina los elementos ya creados
            $(".actuators-off").empty();

            let stack_actuators = [];
            window.arrayActuators = [];
            $(".actuators-sector").each(function () {
                if (this.attributes.style.value == "") {
                    //Recoge el id del dropdown
                    let delay_id = this.children[0].children[0].children[0].id;
                    let actuador_id = this.children[2].children[0].id;

                    let estado_id = this.children[3].children[0].id;
                    // console.log($("#" + actuador_id + " :selected")[0].innerText);
                    let actuador = {
                        actuador: $("#" + actuador_id + " :selected").val(),
                        estado  : $("#" + estado_id + " :selected").val(),
                        delay   : $("#" + delay_id).val()
                    };

                    let actuadorTime = {
                        actuador   : $("#" + actuador_id + " :selected")[0].innerText,
                        actuador_id: $("#" + actuador_id + " :selected").val(),
                        estado     : $("#" + estado_id + " :selected").val(),
                        delay      : $("#" + delay_id).val()
                    };

                    // se guarda en la pila
                    stack_actuators.push(actuador);

                    window.arrayActuators.push(actuadorTime);
                }

            });

            while (stack_actuators.length > 0) {
                let actuator = addSecuence('off');
                let values = stack_actuators.pop();
                // console.log(values);
                let actuador_id = actuator[0].children[2].children[0].id;
                let estado_id = actuator[0].children[3].children[0].id;
                let delay_id = actuator[0].children[4].children[0].children[0].id;
                console.log(Object.values(values));
                $("#" + actuador_id).val(Object.values(values)[0]);
                if (Object.values(values)[1] == 1) {
                    $("#" + estado_id).val(0);
                } else {
                    $("#" + estado_id).val(1);
                }

                $("#" + delay_id).val(Object.values(values)[2]);
            }

        }); //APAGADO ASISTIDO//


        let countOn = 0;
        let countOff = 0;

        var deleteStep = function (id) {
            ocultarTimeline();
            $(".actuator-sector" + id).remove();
        };

        var addInMidleSecuence = function (item, elem_id) {
            ocultarTimeline();
            var tem = $('.actuator-sector-template').html();

            if (item == 'on') {
                countOn = countOn + 1;
                var id = item + countOn.toString();
                tem = tem.replaceAll("%n", countOn);
                tem = tem.replaceAll("%t", item);
                $(tem).insertAfter($(".actuator-sector" + elem_id));

                $('#actuators-step' + id).show();
                $('#pre-delay' + id).show();
                var btn_delete = $(".btn-delete-step" + id);
                btn_delete.click(function () {
                    deleteStep(id);
                });

                var btn_add_up = $(".btn-add-step" + id);
                btn_add_up.click(function () {
                    addInMidleSecuence(item, id);
                });
            }

            if (item == 'off') {
                countOff = countOff + 1;
                var id = item + countOff.toString();
                tem = tem.replaceAll("%n", countOff);
                tem = tem.replaceAll("%t", item);
                $(tem).insertAfter($(".actuator-sector" + elem_id));

                $('#actuators-step' + id).show();
                $('#post-delay' + id).show();
                var btn_delete = $(".btn-delete-step" + id);
                btn_delete.click(function () {
                    deleteStep(id);
                });

                var btn_add_up = $(".btn-add-step" + id);
                btn_add_up.click(function () {
                    addInMidleSecuence(item, id);
                });


            }

        };

        var addSecuence = function (item) {
            ocultarTimeline();
            let tem = $('.actuator-sector-template').html();

            if (item == 'on') {
                countOn = countOn + 1;
                let id = item + countOn.toString();
                tem = tem.replaceAll("%n", countOn);
                tem = tem.replaceAll("%t", item);

                $('.actuators-on').append(tem);

                $('#actuators-step' + id).show();
                $('#pre-delay' + id).show();
                let btn_delete = $(".btn-delete-step" + id);
                btn_delete.click(function () {
                    deleteStep(id);
                });

                let btn_add_up = $(".btn-add-step" + id);
                btn_add_up.click(function () {
                    addInMidleSecuence(item, id);
                });

                // setTimeout(() => {
                //     // $('.selectpicker').selectpicker('refresh');
                //     $('.selectpicker').selectpicker('render');
                // }, 200);
                return $('#actuators-step' + id);
            }

            if (item == 'off') {

                countOff = countOff + 1;
                let id = item + countOff.toString();
                tem = tem.replaceAll("%n", countOff);
                tem = tem.replaceAll("%t", item);
                $('.actuators-off').append(tem);

                $('#actuators-step' + id).show();
                $('#post-delay' + id).show();
                let btn_delete = $(".btn-delete-step" + id);
                btn_delete.click(function () {
                    deleteStep(id);
                });

                let btn_add_up = $(".btn-add-step" + id);
                btn_add_up.click(function () {
                    addInMidleSecuence(item, id);
                });

                setTimeout(() => {
                    $('.selectpicker').selectpicker('refresh');
                    $('.selectpicker').selectpicker('render');
                }, 100);
                return $('#actuators-step' + id);
            }


        };


        if ($('.add-actuator-on').length) {
            $('.add-actuator-on').click(function () {
                addSecuence('on');
            });
            // $('.add-actuator-on').change();
        }

        if ($('.add-actuator-off').length) {
            $('.add-actuator-off').click(function () {
                addSecuence('off');
            });
            // $('.add-actuator-off').change();
        }

        var ocultarTimeline = function () {
            $("#myTimeline").hide();
            $("#myTimeline2").hide();
            $(".show-timeline").show();
        }

        //// EDIT ////
        /**
         * Recupera las secuencias de encendido y apagado
         *  Las construye.
         *
         *  Para que funcione tiene que tener el timeout mínimo de 200
         */
        setTimeout(() => {
            if ($("#edit-sequence-on").length) {
                let sequences = Object.entries(JSON.parse($("#edit-sequence-on").val()));
                sequences.forEach(function (sequence) {
                    let valores = sequence['1'];
                    let actuator = addSecuence('on');

                    let actuador_id = actuator[0].children[2].children[0].id;
                    let estado_id = actuator[0].children[3].children[0].id;
                    let delay_id = actuator[0].children[0].children[0].children[0].id;

                    $("#" + actuador_id).val(valores.actuator_uid);
                    $("#" + estado_id).val(valores.estado);
                    $("#" + delay_id).val(valores.pre_delay);

                });
            }

            if ($("#edit-sequence-off").length) {
                let off = Object.entries(JSON.parse($("#edit-sequence-off").val()));
                off.forEach(function (sequence) {
                    let valores = sequence['1'];
                    let actuator = addSecuence('off');

                    let actuador_id = actuator[0].children[2].children[0].id;
                    let estado_id = actuator[0].children[3].children[0].id;
                    let delay_id = actuator[0].children[4].children[0].children[0].id;

                    $("#" + actuador_id).val(valores.actuator_uid);
                    $("#" + estado_id).val(valores.estado);
                    $("#" + delay_id).val(valores.post_delay);
                })
            }
            //EDIT //

        }, 400);
    });

    /*
     * Add conditionss
     */
    $(document).ready(function () {
        let ccountOn = 0;
        let ccountOff = 0;

        var loadElements = function (item, id) {
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-element'),
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {

                    if (data.ad == "a") {
                        $("#con-opt0-" + id).hide();
                        $("#con-opt1-" + id).hide();
                        $("#con-opt2-" + id).show();
                        $("#con-opt3-" + id).show();
                        $("#con-opt4-" + id).show();
                        $("#condition" + id).val("2");
                        $("#value_sensor" + id).show();
                        $("#measure" + id).html(data.measure);
                    } else {
                        $("#con-opt0-" + id).show();
                        $("#con-opt1-" + id).show();
                        $("#con-opt2-" + id).hide();
                        $("#con-opt3-" + id).hide();
                        $("#con-opt4-" + id).hide();
                        $("#condition" + id).val("1");
                        $("#value_sensor" + id).hide();
                        $("#measure" + id).html(null);
                    }
                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR element.js -> url-element', jqXHR);
                }

            })
        };

        var changeIcon = function (item, id) {

            if (item.attr("value") == 0) {
                item.find(".fa-ban").hide();
                item.find("#info-condition" + id).attr("value", 1);
                item.attr("value", 1)
            } else {
                item.find(".fa-ban").show();
                item.find("#info-condition" + id).attr("value", 0);
                item.attr("value", 0);
            }
        }

        var deleteCondition = function (id) {
            $(".condition-sector" + id).remove();
        };

        var addCondition = function (item) {
            var tem = $('.condition-sector-template').html();

            if (item == 'on') {
                ccountOn++;
                let id = item + ccountOn.toString();
                tem = tem.replaceAll("%n", ccountOn);
                tem = tem.replaceAll("%t", item);
                $('.conditions-on').append(tem);

                $('#conditions-sector' + id).show();
                let btn_delete = $(".btn-delete-condition" + id);
                btn_delete.click(function () {
                    deleteCondition(id);
                });

                let select_sensor = $(".sensor" + id);
                select_sensor.on('change',function () {
                    loadElements($(this), id);
                });

                return $('#conditions-sector' + id);

            }

            if (item == 'off') {
                ccountOff = ccountOff + 1;
                let id = item + ccountOff.toString();
                tem = tem.replaceAll("%n", ccountOff);
                tem = tem.replaceAll("%t", item);
                $('.conditions-off').append(tem);

                $('#conditions-sector' + id).show();
                let btn_delete = $(".btn-delete-condition" + id);
                btn_delete.click(function () {
                    deleteCondition(id);
                });

                let select_sensor = $(".sensor" + id);
                select_sensor.on('change',function () {
                    loadElements($(this), id);
                });

                let btn_info = $(".btn-info-condition" + id);
                btn_info.show();
                btn_info.click(function () {
                    changeIcon($(this), id)
                })

                $(".stop_type_box" + id).show();

                $("#none_noti" + id).click(function () {
                    let checked = $("#none_noti" + id).prop('checked');
                    $("#app_noti" + id).prop('checked', !checked);
                    $("#sms_noti" + id).prop('checked', !checked);
                    $("#email_noti" + id).prop('checked', !checked);
                })

                $("#app_noti" + id).click(function () {
                    $("#none_noti" + id).prop('checked', false)
                })
                $("#sms_noti" + id).click(function () {
                    $("#none_noti" + id).prop('checked', false)
                })
                $("#email_noti" + id).click(function () {
                    $("#none_noti" + id).prop('checked', false)
                })

                return $('#conditions-sector' + id);
            }

        };


        if ($('.add-condition-on').length) {
            $('.add-condition-on').click(function () {
                addCondition('on');
            });
            $('.add-condition-on').change();
        }

        if ($('.add-condition-off').length) {
            $('.add-condition-off').click(function () {
                addCondition('off');
            });
            $('.add-condition-off').change();
        }

        $(".invert-conditions").click(function () {
            $(".conditions-off").empty();
            let stack_conditions = [];
            // INVERTED CONDITIONS
            $(".conditions-sector").each(function () {
                if (this.attributes.style.value == "") {
                    let sensor_id = this.children[2].children[0].id;
                    let condition_id = this.children[3].children[0].id;
                    let value_id = this.children[4].children[0].id;
                    let measure_id = this.children[4].children[1].id;

                    let condition = {
                        sensor   : $("#" + sensor_id + " :selected").val(),
                        condition: $("#" + condition_id + " :selected").val(),
                        value    : $("#" + value_id).val(),
                        measure  : $("#" + measure_id).text()
                    };

                    if ($("#" + condition_id + " :selected").val() != 4) {
                        // se guarda en la pila
                        stack_conditions.push(condition);
                    }
                }
            });

            for (let i = 0; i < stack_conditions.length; i++) {
                let condition = addCondition('off');
                let values = stack_conditions[i];

                let sensor_id = condition[0].children[2].children[0].id;
                let condition_id = condition[0].children[3].children[0].id;
                let value_id = condition[0].children[4].children[0].id;
                let measure_id = condition[0].children[4].children[1].id;

                $("#" + sensor_id).val(Object.values(values)[0]);

                if (Object.values(values)[1] == 1) {
                    $("#" + condition_id).val(0);
                    $("#" + value_id).hide();
                    $("#" + measure_id).hide();
                } else if (Object.values(values)[1] == 0) {
                    $("#" + condition_id).val(1);
                    $("#" + value_id).hide();
                    $("#" + measure_id).hide();
                } else if (Object.values(values)[1] == 2) {
                    $("#" + condition_id).val(3);
                } else if (Object.values(values)[1] == 3) {
                    $("#" + condition_id).val(2);
                }

                $("#" + value_id).val(Object.values(values)[2]);
                $("#" + measure_id).text(Object.values(values)[3]);
            }
            ;
        });

        //// EDIT ////
        /**
         * Recupera las secuencias de encendido y apagado
         *  Las construye.
         *
         *  Para que funcione tiene que tener el timeout mínimo de 200
         */
        setTimeout(() => {
            if ($("#edit-condition-on").length) {
                let conditions = Object.entries(JSON.parse($("#edit-condition-on").val()));
                conditions.forEach(function (condition) {
                    let valores = condition['1'];
                    let con = addCondition('on');

                    let sensor_id = con[0].children[2].children[0].id;
                    let condition_id = con[0].children[3].children[0].id;
                    let value_id = con[0].children[4].children[0].id;
                    let measure_id = con[0].children[4].children[1].id;

                    $("#" + sensor_id).val(valores.sensor_id);
                    $("#" + condition_id).val(valores.operator);
                    let id = sensor_id.substring(sensor_id.length - 3);

                    if (valores.operator == 1 || valores.operator == 0) {
                        $("#" + value_id).hide();
                        $("#" + measure_id).hide();

                        $("#con-opt0-" + id).show();
                        $("#con-opt1-" + id).show();
                        $("#con-opt2-" + id).hide();
                        $("#con-opt3-" + id).hide();
                        $("#con-opt4-" + id).hide();
                    } else {
                        $("#" + value_id).val(valores.value);
                        $("#" + measure_id).text(valores.measure_id);

                        $("#con-opt0-" + id).hide();
                        $("#con-opt1-" + id).hide();
                        $("#con-opt2-" + id).show();
                        $("#con-opt3-" + id).show();
                        $("#con-opt4-" + id).show();
                    }


                });
            }

            //Recoge los valores de html y rellena los campos
            if ($("#edit-condition-off").length) {
                let conditions = Object.entries(JSON.parse($("#edit-condition-off").val()));
                conditions.forEach(function (condition) {
                    let valores = condition['1'];
                    let con = addCondition('off');

                    let sensor_id = con[0].children[2].children[0].id;
                    let condition_id = con[0].children[3].children[0].id;
                    let value_id = con[0].children[4].children[0].id;
                    let measure_id = con[0].children[4].children[1].id;
                    // Sacamos el id para poder esconder y mostrar las opciones
                    let id = sensor_id.substring(sensor_id.length - 4);

                    $("#" + sensor_id).val(valores.sensor_id);
                    $("#" + condition_id).val(valores.operator);
                    if (valores.operator == 1 || valores.operator == 0) {
                        $("#" + value_id).hide();
                        $("#" + measure_id).hide();

                        $("#con-opt0-" + id).show();
                        $("#con-opt1-" + id).show();
                        $("#con-opt2-" + id).hide();
                        $("#con-opt3-" + id).hide();
                        $("#con-opt4-" + id).hide();
                    } else {
                        $("#" + value_id).val(valores.value);
                        $("#" + measure_id).text(valores.measure_id);

                        $("#con-opt0-" + id).hide();
                        $("#con-opt1-" + id).hide();
                        $("#con-opt2-" + id).show();
                        $("#con-opt3-" + id).show();
                        $("#con-opt4-" + id).show();
                    }

                    if(valores.notification != ""){
                        let notification = $("#setNotificationOptions" + id);
                        let modal = notification[0].childNodes[1].childNodes[1].childNodes[3]
                        let email = $("#email_noti" + id).prop("checked", valores.notification.mail != 0);
                        let sms = $("#sms_noti" + id).prop("checked", valores.notification.sms != 0);
                        let app = $("#app_noti" + id).prop("checked", valores.notification.app != 0);
                        let no;
                        if (valores.notification.mail != 1 && valores.notification.sms != 1 && valores.notification.app != 1) {
                            no = true;
                        }
                        else {
                            no = false;
                        }
                        let noNotificar = $("#none_noti" + id).prop("checked",no);
                        let mensaje = $("#msg" + id).text(valores.notification.msg);
                        /**
                         * Habrá que hacer un foreach de los contactos
                         * @type {*}
                         */

                        Object.keys(valores.notification.contacts).forEach(key =>{
                            let contactos = $("#contact_send" + id+key).prop("checked", valores.notification.contacts[key] !=0 );
                        });
                    }





                });
            }
        },1100);
        //   EDIT   //

    });


})(jQuery);

