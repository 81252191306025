/*!
 * 
 */

(function (global) {
    "use strict";
    // catch javascript errors
    window.onerror = function (message, file, line, col, error) {        
        //PulsarLogger.exception(error, file, line, col);
        return false;
    };
})(jQuery);    
    
   /**
    * 
    *   var logger = new Logger({
    *       name  : 'myContext',
    *       level : 'debug'
    *   });
    * 
    *   logger.time('timername', 'start');  // start timer
    *   logger.debug('Debug!');             // [DD/MM/YYYY HH:ii] [MYCONTEXT] Debug!
    *   logger.info('Info!');               // [DD/MM/YYYY HH:ii] [MYCONTEXT] Info!
    *   logger.warn('Warning!');            // [DD/MM/YYYY HH:ii] [MYCONTEXT] Warning!
    *   logger.error('Error!');             // [DD/MM/YYYY HH:ii] [MYCONTEXT] Error!
    *   logger.timeEnd('timername');        // finish active timer
    */
    var Logger = function(options) {
        this.LOG   = 'LOG',
        this.DEBUG = 'DEBUG';
        this.INFO  = 'INFO';
        this.TABLE = 'TABLE';   
        this.TIME  = 'TIME';
        this.WARN  = 'WARN';
        this.ERROR = 'ERROR';
        
        var defaults = {
            name       : null,           // add context to logs
            timestamps : true,           // timestamps logs
            handler    : 'console',      // principal handler
            level      : this.LOG,     // show logs with this min level
            sslevel    : this.ERROR,     // notify server-side with this min level
            sshandler  : 'ajax'          // server-side logs handler, null|false to disabled
        };
        
        // set options by extends defaults
        this.options = $.extend(defaults, options, arguments);
        
        // mutate level from string to int
        this.options.level   = this.options.level   ? this.levels[this.options.level.toUpperCase()]   : null;
        this.options.sslevel = this.options.sslevel ? this.levels[this.options.sslevel.toUpperCase()] : null;
    }
    
    // PRODUCT FUNCTIONS
    Logger.prototype = {
        levels : {
            LOG       : 0,
            DEBUG     : 1,
            INFO      : 2,
            TIME      : 3,
            TABLE     : 4,
            WARN      : 5,
            ERROR     : 6,
            EXCEPTION : 99,
        },
        log : function( /* arguments */ ) {
            this.exec(this.levels.LOG, arguments);
        },        
        debug : function( /* arguments */ ) {
            this.exec(this.levels.DEBUG, arguments);
        },
        info : function( /* arguments */ ) {
            this.exec(this.levels.INFO, arguments);
        },    
        warn : function( /* arguments */ ) {
            this.exec(this.levels.WARN, arguments);
        },
        error : function( /* arguments */ ) {
            this.exec(this.levels.ERROR, arguments);
        },
        table : function( /* arguments */ ) {
            this.exec(this.levels.TABLE, arguments);
        },
        time : function( /* arguments */ ) {
            this.exec(this.levels.TIME, arguments);
        },
        timeEnd : function( /* arguments */ ) {
            this.exec(this.levels.TIME, arguments);
        },
        exception : function( message, file, line, col ) {
            // get level
            var level = this.levels.EXCEPTION;
            // check exception options
            if (this.options.sshandler && this.enabledFor(level) && this.enabledForServerSide(level)) {
                // send exception by server-side handler
                this.handlers[this.options.sshandler](merge({ level: level }, this.options), message, file, line, col);
            }            
        },
        
        // exec
        exec: function (level, args) {
            if (this.options.handler && this.enabledFor(level)) {
                this.handlers[this.options.handler](args, merge({ level: level }, this.options));
            }
            if (this.options.sshandler && this.enabledFor(level) && this.enabledForServerSide(level)) {
                // send exception by server-side handler
                this.handlers[this.options.sshandler](merge({ level: level }, this.options), args);
            }            
        },
        
        enabledFor : function(level) {
            return level >= this.options.level;
        }, 
 
        enabledForServerSide : function(level) {
            return level >= this.options.sslevel;
        },
        
        handlers : {
            console : function(messages, context) {                
                // Convert arguments object to Array.
                messages = Array.prototype.slice.call(messages);

                var hdlr = console.log;
                var timerLabel;
                
                if (context.level === PulsarLogger.levels.TIME) {
                    timerLabel = (context.name ? '[' + context.name.toUpperCase() + '] ' : '') + messages[0];

                    if (messages[1] === 'start') {
                        if (console.time) {
                            console.time(timerLabel);
                        }
                        else {
                            timerStartTimeByLabelMap[timerLabel] = new Date().getTime();
                        }
                    }
                    else {
                        if (console.timeEnd) {
                            console.timeEnd(timerLabel);
                        }
                        else {
                            invokeConsoleMethod(hdlr, [ timerLabel + ': ' +
                                (new Date().getTime() - timerStartTimeByLabelMap[timerLabel]) + 'ms' ]);
                        }
                    }
                }
                else {     
                    // Delegate through to custom warn/error loggers if present on the console.
                    if (context.level === PulsarLogger.levels.WARN && console.warn) {
                        hdlr = console.warn;
                    } else if (context.level === PulsarLogger.levels.TABLE) {
                        if (console.table) {
                            hdlr = console.table;
                        } else {
                            hdlr = console.info;
                        }
                    } else if (context.level === PulsarLogger.ERROR && console.error) {
                        hdlr = console.error;
                    } else if (context.level === PulsarLogger.levels.INFO && console.info) {
                        hdlr = console.info;
                    } else if (context.level === PulsarLogger.levels.DEBUG && console.debug) {
                        hdlr = console.debug;
                    } else if (context.level === PulsarLogger.levels.EXCEPTION && console.error) {
                        return console.log("[LOGGER] EXCEPTION ", messages);
                    }

                    if (hdlr != console.table) {
                        formatter(messages, context);
                    }
                    
                    invokeConsoleMethod(hdlr, messages);
                }              
            },
            ajax : function(context, message, file, line, column) {
                $.ajax({
                    method : 'post',
                    url  : '/index.php?controller=logger&action=submit',
                    data : {
                        level    : this.getLevel(context.level),
                        messages : message,
                        file     : file,
                        line     : line || null,
                        column   : column || null
                    },
                    dataType : 'json',
                    success : function(response, textStatus, jqXHR) {
                        /* success */
                        console.log('[LOGGER] ajax response', response);
                    },
                    error : function(jqXHR, textStatus, errorThrown) {
                        console.log('[LOGGER] ERROR ajax', jqXHR, textStatus, errorThrown);
                    }
                })
                
            },
            getLevel : function(level) {
                for( var objective in PulsarLogger.levels ) {
                    if( PulsarLogger.levels[ objective ] === level )
                        return objective;
                }
                return false;
            }            
        },
        
    }
       
    
    // Support for IE8+ (and other, slightly more sane environments)
    var invokeConsoleMethod = function (hdlr, messages) {
        Function.prototype.apply.call(hdlr, console, messages);
    };
        
    // Super exciting object merger-matron 9000 adding another 100 bytes to your download.
    var merge = function () {
        var args = arguments, target = args[0], key, i;
        for (i = 1; i < args.length; i++) {
            for (key in args[i]) {
                if (!(key in target) && args[i].hasOwnProperty(key)) {
                    target[key] = args[i][key];
                }
            }
        }
        return target;
    };  
    
    /**
     * format messages before show or send
     */
    var formatter = function(messages, context) {
        // Prepend the logger's name to the log message for easy identification.     
        if (context.name) {
            messages.unshift("[" + context.name.toUpperCase() + "]");
        }       
        if (context.timestamps) {
            messages.unshift("[" + getFormatedDate() + "]");
        }         
    };
    
    /**
     * return a formatted date
     */    
    function getFormatedDate() {
        var date    = new Date();
        var hours   = date.getHours();
        var minutes = date.getMinutes();
        hours       = hours < 10   ? '0'+hours   : hours;
        minutes     = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes;
        return date.getDate() + "/" + (date.getMonth()+1)+ "/" + date.getFullYear() + " " + strTime;
    }    
    
    /**
     * create a defaul logger instance for exceptions
     */    
    var PulsarLogger = new Logger({
        name : 'exceptions' 
    });