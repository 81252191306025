/**
 * contact.js
  */
(function ($) {
    "use strict";
    
    var logger = null;
    
    $(document).ready(function () {
        
        logger  = new Logger({
                name : 'contact',
                level : 'log'
        });

        if ($('#contact').length > 0) {
            var image = $('#contact').data('parallax');
            console.log(image);
            $('.parallax').parallax({
                imageSrc: image,
                naturalWidth : 1920,
                naturalHeight : 1121,
                bleed : 100,
            });
        }
        
        $('#contact #submit').click(function() {
            validateForm(function() {
            	if (!reCaptchaCheck()) {
                    return false;
                }
               submitForm(); 
            });
        });
    });
    
    var validateForm = function(callback) {
        if (!$('#contact form').validator.fails()) {
            logger.log('Validation OK');
            
            callback();
        }
    };
    
    var submitForm = function() {
        $.ajax({
            type  : 'POST',
            url   : $('#contact form').attr("action"),
            data  : $('#contact form').serialize(), 
            dataType : 'json',
            //or your custom data either as object {foo: "bar", ...} or foo=bar&...
            beforeSend: function() {
                $('#contact form').hide();
                $('#formSending').show();
                $('#formError').hide();
            },
            success : function(response, textStatus, jqXHR) {
                $('#formSending').hide();
                $('#formSent').show();
                logger.log('submitForm success', response);
                if (response.result) {
                    
                }
            },
            error : function(jqXHR, textStatus, errorThrown) {
                //$('#contact form').show();
                $('#formSending').hide();
                $('#formError').show();
                logger.log('submitForm ERROR', jqXHR, textStatus, errorThrown);
            }
        });        
    };
    
}(jQuery));