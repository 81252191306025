/**
 * program.js
 * Created by PULSARINARA\d.monfort on 25/01/22.
 */

(function ($) {
    "use strict";
    $(document).ready(function () {
        if ($(".program-view").length) {
            let changeVal = function (item) {
                if (item.attr("value") == 0) {
                    item.attr("value", 1);
                    item.children(0).attr("value", 1);
                    item.addClass("btn-info");
                } else {
                    item.attr("value", 0);
                    item.children(0).attr("value", 0);
                    item.removeClass("btn-info");
                }
            };
            $(".mon").click(function () {
                changeVal($(this));
            });
            $(".tus").click(function () {
                changeVal($(this));
            });
            $(".wed").click(function () {
                changeVal($(this));
            });
            $(".thu").click(function () {
                changeVal($(this));
            });
            $(".fri").click(function () {
                changeVal($(this));
            });
            $(".sat").click(function () {
                changeVal($(this));
            });
            $(".sun").click(function () {
                changeVal($(this));
            });

            let setVal = function (item) {
                if (item.attr("value") == 0) {
                    item.removeClass("btn-info");
                } else {
                    item.addClass("btn-info");
                }
            };
            // setTimeout(() => {
                if ($(".id_edit_program").length == 1) {
                    setVal($(".mon"));
                    setVal($(".tus"));
                    setVal($(".wed"));
                    setVal($(".thu"));
                    setVal($(".fri"));
                    setVal($(".sat"));
                    setVal($(".sun"));
                }
            // }, 400);
        }
    });

    $(document).ready(function () {
        if ($(".program-view").length) {
            const labels = document.querySelector("#advanced-init-val");

            $(".btn-advanced-init").click(function () {
                if ($(".advanced-init").is(":hidden")) {
                    $("#advanced-init-val").attr("value", 1);
                    $(".advanced-init").show();
                    $(".label-advanced-init").text(labels.dataset.hidden);
                    $(".init-down").hide();
                    $(".init-up").show();
                } else {
                    $("#advanced-init-val").attr("value", 0);
                    $(".advanced-init").hide();
                    $(".label-advanced-init").text(labels.dataset.show);
                    $(".init-down").show();
                    $(".init-up").hide();
                }
            });
        }
    });

    $(document).ready(function () {
        if ($(".program-view").length) {
            let count = 0;

            let deleteSector = function (id) {
                $(".sector-" + id).remove();
            };

            let addTurn = function () {
                count++;
                let id = count;
                let turn = $(".turn-template").html();
                turn = turn.replaceAll("%n", count);
                $(".turn-container").append(turn);

                let btn_delete = $(".btn-delete-sector-" + id);
                btn_delete.click(function () {
                    deleteSector(id);
                });

                return $("#turn-" + id);
            };


            $(".btn-add-turn").click(function () {
                addTurn();
            });

            setTimeout(() => {
                if ($("#edit-turn-container").length) {
                    let turns = Object.entries(JSON.parse($("#edit-turn").val()))
                    turns.forEach(function (turn) {
                        let valores = turn['1'];
                        let turno = addTurn();

                        let sectors_id = turno[0].children[1].children[0].children[0].children[0].children[0].id;
                        let timer_id = turno[0].children[1].children[0].children[1].children[1].children[0].id;
                        let uid_id = turno[0].children[1].children[0].children[2].id;


                        // console.log(duracion);
                        // Asignamos el uid para luego poder trabajar mejor con los elementos.
                        $("#" + uid_id).val(valores.uid);
                        $("#" + timer_id).val(valores.duration);

                        let pos = sectors_id.indexOf(":", 8);
                        let id = sectors_id.substr(pos);
                        let sectores = valores.sectors;
                        sectores.forEach(function (sector) {
                            $("#turns" + id + sector.id).prop('checked', true);
                        });

                    })
                }
            }, 1200);
        }
    });

    $(document).ready(function () {
        let loadContacts = function (item) {
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-contacts'),
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {
                    // vaciamos los turnos
                    $(".turn-container").empty();

                    if ($(".address_list").length) {

                        $(".contacts_list").empty();
                        let contacts = data["contacts"];
                        $.each(contacts, function (name) {
                            // console.log(elements[name]);
                            let contact = contacts[name];
                            $(".contacts_list").append('<li value="' + contact["id"] + '"><input type="checkbox" id="contact_send%t%n' + contact["id"] + '" name="notification[contact_send][%t][%n][' + contact["id"] + ']"><label for="contact_send%t%n' + contact["id"] + '">&nbsp;&nbsp;  ' + contact["name"] + ' | ' + contact["tlf"] + '&nbsp; |&nbsp;  ' + contact["email"] + '</label></li>')
                        });
                    }

                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR  program.js -> url-contacts', jqXHR);
                }

            })
        };

        if ($('.select-contact').length) {
            $('.select-contact').on('change', function () {
                loadContacts($(this));
            });

            $('.select-contact').change();
        }

    });


    $(document).ready(function () {
        let loadElements = function (item) {
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-element'),
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {
                    // vaciamos los turnos
                    $(".turn-container").empty();


                    if ($(".sector-turns").length) {

                        let elements = data["sectors"];
                        $(".sector-turns").empty();
                        let turnos = $(".sector-turns");
                        $.each(turnos, function (turno) {
                            let id = turnos[turno]["id"].substr(-1);
                            if (id == "n" && $(".sector-turns").length == 1) {
                                id = "%n"
                                $.each(elements, function (name) {
                                    // console.log(elements[name]);
                                    let element = elements[name];
                                    // console.log(element);
                                    $(".sector-turns").append('<li>' +
                                        '<input type="checkbox" id="turns' + id + element["id"] + '" name="turns[' + id + '][' + element["id"] + ']">' +
                                        '<label for="turns' + id + element["id"] + '">&nbsp;&nbsp;' + element["name"] + '</label>' +
                                        '</li>');

                                });

                            } else {
                                $("#sector-turn-" + id).empty();
                                $.each(elements, function (name) {
                                    // console.log(elements[name]);
                                    let element = elements[name];
                                    // console.log(element);
                                    $("#sector-turn-" + id).append('<li>' +
                                        '<input type="checkbox" id="turns' + id + element["id"] + '" name="turns[' + id + '][' + element["id"] + ']">' +
                                        '<label for="turns' + id + element["id"] + '">&nbsp;&nbsp;' + element["name"] + '</label>' +
                                        '</li>');

                                });
                            }

                        });
                    }

                    if ($(".element-conditions").length) {

                        $(".element-conditions").find('option').remove();
                        let elements = data["sensors"];
                        $.each(elements, function (name) {
                            // console.log(elements[name]);
                            let element = elements[name];
                            $(".element-conditions").append('<option value="' + element["id"] + '">' + element["name"] + '</option>');

                        });
                    }
                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR -> url-element', jqXHR);
                }

            })
        };

        if ($('.select-sensor').length) {
            $('.select-sensor').on('change', function () {
                loadElements($(this));
            });

            $('.select-sensor').change();
        }

    });

    $(document).ready(function () {
        if ($(".program-view").length) {
            if ($(".turn-container").length) {

            }
        }
    });
})(jQuery);