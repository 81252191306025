$(document).ready(function () {
    if ($("#chart_plot_area").length) {
        init_chart_graph();
    }
});

// init_old_chart_graph();

function init_old_chart_graph2() {
    if ($('.pulsar_chart')) {
        if (typeof (Chart) === 'undefined') {
            console.log('Doughnut Error: Chart undefined.');
            return;
        }

        c = 0;
        $('.pulsar_chart').each(function () {
            var url      = $(this).data('url');
            var id       = 'canvasInfoDoughnut' + c;
            headerLabels = $(this).data('headerlabels');
            headerValues = $(this).data('headervalues');
            console.log('Bucle: ' + id);

            // Generate and put: base structure for canvas and data table
            $(this).html(
                genHTMLBase(headerLabels, headerValues, url, id)
            );

            // Get the data
            $.ajax({
                type    : 'POST',
                url     : url,
                dataType: 'json',
                cache   : false,
                success : function (data) {
                    if (data) {
                        html         = '';
                        values_array = [];
                        labels_array = [];

                        // Generate and put data contents in data-table-structure previously generated
                        $('#' + id + '_table').html(
                            genDataTable(data)
                        );

                        console.log('Ajax: ' + id);
                        console.log(data);

                        // Construct data structure for canvas and create a new instance for graph
                        runDoughnut(id, labels_array, values_array);
                    }
                },
                error   : function (xhr, status, error) {
                    console.log('Error Ajax Donut');
                }
            });

            c++;
        });

        /**
         * Generate the base structure for canvas and data table
         * @param headerLabels
         * @param headerValues
         * @param url
         * @param id
         * @returns {string}
         */
        function genHTMLBase(headerLabels, headerValues, url, id) {
            return '<table class="" style="width:100%">' +
                '<tr>' +
                '<th style="width:37%;"></th>' +
                '<th>' +
                '<div class="col-lg-8 col-md-8 col-sm-7 col-xs-7">' +
                '<p class="">' + headerLabels + '</p>' +
                '</div>' +
                '<div class="col-lg-4 col-md-4 col-sm-5 col-xs-5">' +
                '<p class="">' + headerValues + '</p>' +
                '</div>' +
                '</th>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<canvas id="' + id + '" class="canvasInfoDoughnut" height="140" width="140"' +
                'style="margin: 15px 10px 10px 0" data-url="' + url + '"></canvas>' +
                '</td>' +
                '<td colspan="2" style="vertical-align: top">' +
                '<table class="tile_info" id="' + id + '_table"></table>' +
                '</td>' +
                '</tr>' +
                '</table>';
        }


        /**
         * Generate the data contents for data-table-structure
         * @param data
         * @returns {string}
         */
        function genDataTable(data) {
            html = '';
            $.each(data, function (index, element) {
                var val = '';

                if (element.percentage) {
                    val = element.value + ' (' + element.percentage + '%)';
                } else {
                    val = element.value;
                }
                html = html + '<tr><td><p><i class="fa fa-square blue"></i>' + element.label +
                    '</p></td><td style="min-width: inherit">' + val + '</td></tr>';

                labels_array.push(element.label);
                values_array.push(element.value);
            });
            return html;
        }

        /**
         * Construct data structure for canvas and create a new instance for graph
         * @param id
         * @param labels
         * @param values
         */
        function runDoughnut(id, labels, values) {
            if ($('#' + id).length) {
                var chart_doughnut_settings = {
                    type            : 'doughnut',
                    tooltipFillColor: "rgba(51, 51, 51, 0.55)",
                    data            : {
                        labels  : labels,
                        datasets: [{
                            data                : values,
                            backgroundColor     : [
                                "#9B59B6",
                                "#E74C3C",
                                "#26B99A",
                                "#BDC3C7",
                                "#3498DB"
                            ],
                            hoverBackgroundColor: [
                                "#B370CF",
                                "#E95E4F",
                                "#36CAAB",
                                "#CFD4D8",
                                "#49A9EA"
                            ]
                        }]
                    },
                    options         : {
                        legend    : false,
                        responsive: false
                    }
                }

                $('#' + id).each(function () {
                    console.log('New Chart: #' + id);
                    var chart_element  = $(this);
                    var chart_doughnut = [];
                    chart_doughnut.push(new Chart(chart_element, chart_doughnut_settings));
                });

            }
        }
    }
}

function init_chart_graph() {

    if (typeof ($.plot) === 'undefined') {
        return;
    }
    if ($(".graph_chart").length) {
        var c = 0;
        $('.graph_chart').each(function () {
            var url = $(this).data('url');
            var id  = 'graphChart' + c;
            console.log('Bucle: ' + id);

            var arr_data1 = [];
            var arr_data2 = [];
            // Get the data
            $.ajax({
                type    : 'POST',
                url     : url,
                dataType: 'json',
                cache   : false,
                success : function (data) {
                    if (data) {
                        arr_data1 = data.transport;
                        arr_data2 = data.unload;
                        // arr_data2 = [
                        //     [gd(2020, 4, 28), 82],
                        //     [gd(2020, 4, 29), 23],
                        //     [gd(2020, 4, 30), 66],
                        //     [gd(2020, 5, 1), 9],
                        //     [gd(2020, 4, 5), 119],
                        //     [gd(2020, 4, 6), 6],
                        //     [gd(2020, 4, 7), 9]
                        // ];
                        // arr_data2 = [
                        //     [1588060106000, 82],
                        //     [1588146506000, 23],
                        //     [1588232906000, 66]
                        // ];

                        console.log('Datos');
                        console.log(arr_data1);
                        console.log(arr_data2);

                        var chart_plot_data = [];
                        for (var i = 0; i < 30; i++) {
                            chart_plot_data.push([new Date(Date.today().add(i).days()).getTime(), randNum() + i + i + 10]);
                        }

                        var chart_plot_settings = {
                            series : {
                                lines     : {
                                    show: false,
                                    fill: true
                                },
                                splines   : {
                                    show     : true,
                                    tension  : 0.4,
                                    lineWidth: 1,
                                    fill     : 0.4
                                },
                                points    : {
                                    radius: 0,
                                    show  : true
                                },
                                shadowSize: 2
                            },
                            grid   : {
                                verticalLines: true,
                                hoverable    : true,
                                clickable    : true,
                                tickColor    : "#d5d5d5",
                                borderWidth  : 1,
                                color        : '#fff'
                            },
                            colors : ["rgba(38, 185, 154, 0.38)", "rgba(3, 88, 106, 0.38)"],
                            xaxis  : {
                                tickColor              : "rgba(51, 51, 51, 0.06)",
                                mode                   : "time",
                                tickSize               : [1, "day"],
                                //tickLength: 10,
                                axisLabel              : "Date",
                                axisLabelUseCanvas     : true,
                                axisLabelFontSizePixels: 12,
                                axisLabelFontFamily    : 'Verdana, Arial',
                                axisLabelPadding       : 10,
                                mode                   : "time",
                                timeBase               : "milliseconds",
                                dayNames               : ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                                monthNames             : ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "dic"],
                                timeformat             : "%d/%b"
                                // timeformat             : "%a %d de %b"
                            },
                            yaxis  : {
                                ticks       : 8,
                                tickColor   : "rgba(51, 51, 51, 0.06)",
                                tickDecimals: 0,
                            },
                            tooltip: false
                        }

                        $.plot($(".graph_chart"), [arr_data1, arr_data2], chart_plot_settings);

                        c++;
                    }
                },
                error   : function (xhr, status, error) {
                    console.log('Error Ajax Donut');
                }
            });

        });
    }
}