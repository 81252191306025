function del_file(item) {
    var item_id = $(item).data('file'),
        url     = $(item).parent().parent().parent().prev().data('url') + '/delfile';

    pulsar.ajax({
        url       : url,
        data      : {
            item_id: item_id
        },
        dataType  : 'json',
        beforeSend: function () {
        },
        success   : function (response, textStatus, jqXHR) {
            if (response.result) $(item).parent().parent().remove();
        },
    });
}

(function () {
    "use strict";

    $(document).ready(function () {
        if ($('.add-file').length) {
            console.log('Loading multifiles');

            $('.add-file').click(function (e) {
                e.preventDefault();

                var url     = $(this).data('url') + '/addfile',
                    partner = $(this).data('partner'),
                    lang    = $(this).data('lang');


                $.ajax({
                    type    : "POST",
                    url     : url,
                    dataType: "json",
                    data    : {
                        partner: partner,
                        lang   : lang
                    },
                    success : function (request) {
                        $('.files').append(request.button);
                        pulsarcdn('clean');
                        pulsarcdn();
                    }
                });
            });
        }
    });
})(jQuery);
