/**
 * auth.js
 */

$(function() {
    $(document).ready(function() {
        $('#login_form').keypress(function(event) {
            if (event.keyCode == 13 || event.which == 13) {
                $(this).closest('form').submit();
            }
        });
    });
}(jQuery));