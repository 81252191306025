/**
 * nodes.js
 * b.rando@agrointelligent.com
 *
 * 13/07/21
 * Último autor : d.monfort@agrointelligent.com
 * Última modificación : 25/08/2022
 */

(function ($) {
    $(document).ready(function () {
        if ($('#load-image-id').length) {
            formData = new FormData()
            window.addEventListener('load', function () {
                const fileInput = document.querySelector('[type=file]')
                fileInput.addEventListener('change', function (e) {
                    const files = e.target.files
                    const promises = []
                    for (let file of files) {
                        promises.push(new Promise(function (resolve, reject) {
                            new Compressor(file, {
                                quality: 0.1,
                                success(result) {
                                    formData.set("image-uploaded",result, result.name)
                                    // formData.append('files[]', result, result.name)
                                    resolve()
                                },
                                error(err) {
                                    reject()
                                },
                            })
                        }))
                    }
                })
            })

            var loadImage = function (item) {
                const $node_id = $("#node_id")[0].value;
                formData.append('node_id', $node_id)
                if (formData.get('image-uploaded').size >= 2000000){
                    alert(`This archive ${formData.get('image-uploaded').name} is bigger than 2MB.`);
                    $('#modalForm').modal('hide');
                    return console.log(`This archive ${formData.get('image-uploaded').name} is bigger than 2MB.`)
                }
                else if(formData.get('image-uploaded').size < 2000000){
                    // alert(`This archive ${formData.get('image-uploaded').name} is less than 2MB.`);
                    console.log(`This archive ${formData.get('image-uploaded').name} is less than 2MB.`)
                }
                $.ajax({
                    type   : "POST",
                    // dataType: "json",
                    url    : item.data('load-img'),
                    enctype: 'multipart/form-data',
                    data   : formData,
                    // data   : {
                    //     node_id: item.data('node'),
                    //     image: input[0].files[0],
                    // },
                    processData: false,
                    contentType: false,
                    success: function (data) {
                        $('#modalForm').modal('hide');
                        location.reload();
                    },
                    error  : function () {
                        alert('Error');
                    }
                });
                return false;
            }
            $('#load-image-id').on('click', function () {
                // $('#image-log-form-id').submit();
                loadImage($(this));
            });
        }
    });
})(jQuery);

(function ($) {
    // Agrega un nuevo formulario clave-valor
    $(document).ready(function () {

        $('#add_json_param').click(function (event) {
            let template = $('#tab_content_template').html();
            $('#customs').append(template);
        })


        var loadInstallations = function (item) {
            console.log(item.val());
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-installation'),
                data    : {
                    item_id: item.val(),
                    node_id: $('#id').val(),
                },
                success : function (data) {


                    if (data.node == null) {
                        if ($("#installations").length) {

                            $("#installations").find('option').remove();
                            $.each(data.installations, function (i, e) {


                                $("#installations").append('<option value="' + e.id + '" >' + e.name + '</option>');

                            });
                        }
                    } else {

                        if ($("#installations").length) {

                            $("#installations").find('option').remove();
                            $.each(data.installations, function (i, e) {


                                if (data.node.installation_id == i) {

                                    $("#installations").append('<option value="' + e.id + '" selected>' + e.name + '</option>');
                                } else {

                                    $("#installations").append('<option value="' + e.id + '" >' + e.name + '</option>');
                                }
                            });
                        }
                    }


                    setTimeout(() => {
                        $('.selectpicker').selectpicker('refresh');
                        $('.selectpicker').selectpicker('render');
                    }, 200);
                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR nodes.js ->url-installations', jqXHR);

                    setTimeout(() => {
                        $('.selectpicker').selectpicker('refresh');
                        $('.selectpicker').selectpicker('render');
                    }, 200);
                }

            })

        };

        if ($('.select_installation').length) {
            $('.select_installation').on('change', function () {
                loadInstallations($(this));
            });

            if ($('#edit').length) {
                $('.select_installation').change();
            }
        }
    });
})(jQuery);


(function ($) {
    "use strict";
    $(document).ready(function () {
        // dataUser();


        function dataUser(item) {
            $.ajax(
                {
                    type    : "POST",
                    dataType: "json",
                    url     : item.data('url-data'),
                    data    : {
                        item_id: item.val(),
                        node_id: $('#id').val(),
                    },
                    success : function (data) {
                        // console.log(data.sensors_a);
                        let ca = 0;
                        let cd = 0;
                        sensors_a = [];
                        sensors_d = [];
                        sensors_dm = [];


                        Object.entries(data.sensors_a).forEach(a => {
                            sensors_a[ca++] = "<option class='sensor_" + a[1].sensor_id + "' value='" + a[1].sensor_id + "' data-conection='" + a[1].sensor_ad + "' clavija='" + a[1].clavija + "'> " + a[1].name + " </option>";
                        });

                        Object.entries(data.sensors_d).forEach(a => {
                            sensors_d[cd++] = "<option class='sensor_" + a[1].sensor_id + "' value='" + a[1].sensor_id + "' data-conection='" + a[1].sensor_ad + "' clavija='" + a[1].clavija + "'> " + a[1].name + " </option>";
                        });

                        Object.entries(data.sensors_dm).forEach(a => {
                            sensors_dm[cd++] = "<option class='sensor_" + a[1].sensor_id + "' value='" + a[1].sensor_id + "' data-conection='" + a[1].sensor_ad + "' clavija='" + a[1].clavija + "'> " + a[1].name + " </option>";
                        });

                        actuators_a = [];
                        actuators_d = [];
                        actuators_dm = [];
                        ca = 0;
                        cd = 0;
                        Object.entries(data.actuators_a).forEach(a => {
                            actuators_a[ca++] = "<option class='actuator_" + a[1].actuator_id + "' value='" + a[1].actuator_id + "' data-conection='" + a[1].actuator_ad + "' clavija='" + a[1].clavija + "'> " + a[1].name + " </option>";
                        });

                        Object.entries(data.actuators_d).forEach(a => {
                            actuators_d[cd++] = "<option class='actuator_" + a[1].actuator_id + "' value='" + a[1].actuator_id + "' data-conection='" + a[1].actuator_ad + "' clavija='" + a[1].clavija + "'> " + a[1].name + " </option>";
                        });

                        Object.entries(data.actuators_dm).forEach(a => {
                            actuators_dm[cd++] = "<option class='actuator_" + a[1].actuator_id + "' value='" + a[1].actuator_id + "' data-conection='" + a[1].actuator_ad + "' clavija='" + a[1].clavija + "'> " + a[1].name + " </option>";
                        });

                        // console.log(sensors_a);
                        // console.log(sensors_d);
                        // console.log(sensors_dm);
                        // console.log(actuators_a);
                        // console.log(actuators_d);
                        // console.log(actuators_dm);

                        fill();

                    },
                    error   : function (jqXHR, textStatus, errorThrown) {
                        console.log('[filterById] ERROR nodes.js -> url-data: ', jqXHR);
                    }

                },
            )
        }


        /*Función que rellena los sensores y actuadores*/
        function fill() {
            /*Cambio del tipo de nodo*/
            if ($('.io-mutator').length) {
                $('.io-mutator').on('change', function () {

                    let an = $(this).find(':selected').data('an');
                    console.log("an :" + an);
                    if (an == 1) {
                        $('#net_analyzer_form').show();
                    } else {
                        $('#net_analyzer_form').hide();
                    }

                    /*SENSORES Y ACTUADORES*/
                    let ins = $(this).find(':selected').data('inputs');
                    let outs = $(this).find(':selected').data('outputs');
                    let conections = $(this).find(':selected').data('conections');
                    // usado para ajustar la clavija en los actuadores [max_in + clavija]
                    let c = 0;
                    $.each(conections['i'], function (i, v) {
                        $('.sensor_item_' + i).data('conection', v);
                        $('.socket_s_' + i).html(" " + i + " " + getType(v));
                        c = i;
                    });

                    $.each(conections['o'], function (i, v) {
                        $('.actuator_item_' + i).data('conection', v);
                        c++;
                        $('.socket_a_' + i).html(" " + c + " " + getType(v));
                    });

                    $('.sensor_select').hide();
                    for (let c = 0; c <= ins; c++) {
                        $('.sensor_select_' + c).show();
                    }

                    $('.actuator_select').hide();
                    for (let c = 0; c <= outs; c++) {
                        $('.actuator_select_' + c).show();
                    }

                    $('.sensor_select').each(function () {
                        if ($(this).is(":hidden")) {
                            $(this).val(0).change();
                        }
                    });
                    $('.actuator_select').each(function () {
                        if ($(this).is(":hidden")) {
                            $(this).val(0).change();
                        }
                    });

                    hidden_conections();

                    // edit_positions();
                });


                var hidden_conections = function () {
                    $('.sensor_item:visible').each(function (i, e) {
                        let con = $(e).data('conection');

                        $(e).empty();
                        $(e).append('<option value="0" data-conection="m">---</option>');

                        $(e).children().each(function (i2, o) {
                            if (con == 'a' || con == 'm') {
                                for (let c = 0; c <= sensors_a.length; c++) {
                                    $(e).append(sensors_a[c]);
                                }
                            }

                            if (con == 'd' || con == 'm') {
                                for (let c = 0; c <= sensors_d.length; c++) {
                                    $(e).append(sensors_d[c]);
                                }
                            }

                            if (con == 'dm') {
                                for (let c = 0; c <= sensors_dm.length; c++) {
                                    $(e).append(sensors_dm[c]);
                                }
                            }
                        });
                    });

                    $('.actuator_item:visible').each(function (i, e) {
                        let con = $(e).data('conection');

                        $(e).empty();
                        $(e).append('<option value="0" data-conection="m">---</option>');

                        $(e).children().each(function (i2, o) {
                            if (con == 'a' || con == 'm') {
                                for (let c = 0; c <= actuators_a.length; c++) {
                                    $(e).append(actuators_a[c]);
                                }
                            }

                            if (con == 'd' || con == 'm') {
                                for (let c = 0; c <= actuators_d.length; c++) {
                                    $(e).append(actuators_d[c]);
                                }
                            }

                            if (con == 'dm') {
                                for (let c = 0; c <= actuators_dm.length; c++) {
                                    $(e).append(actuators_dm[c]);
                                }
                            }
                        });
                    });
                }

                $('.sensor_item').on('change', function () {
                    if ($(this).data('previous') == undefined) {
                        $(this).data('previous', 0);
                    }

                    let value = $(this).val();
                    let previous = $(this).data('previous');
                    $(this).data('previous', value);

                    $.each($(".sensor_item:visible"), function () {
                        $(this).find(".sensor_" + value).hide();
                        $(this).find(".sensor_" + previous).show();
                    });
                });

                $('.actuator_item').on('change', function () {
                    if ($(this).data('previous') == undefined) {
                        $(this).data('previous', 0);
                    }

                    let value = $(this).val();
                    let previous = $(this).data('previous');
                    $(this).data('previous', value);

                    $.each($(".actuator_item:visible"), function () {
                        $(this).find(".actuator_" + value).hide();
                        $(this).find(".actuator_" + previous).show();
                    });
                });

                $('.io-mutator').change();
                if (document.querySelector('#edit_clavija')) {
                    //Recoge los sensores asignados al nodo y los muestra en sus respectivas clavijas
                    let sensors = [];
                    sensors = JSON.parse($('#linked_sen').val());
                    for (let sensor in sensors) {
                        let id = sensors[sensor];
                        $("#sensors_" + id).val(sensor).change();
                    }
                    //Recoge los sensores asignados al nodo y los muestra en sus respectivas clavijas
                    let actuators = [];
                    actuators = JSON.parse($('#linked_act').val());
                    for (let actuator in actuators) {
                        let id = actuators[actuator];
                        $("#actuators_" + id).val(actuator).change();
                    }
                }
            }
        }


        if ($('.select_data').length) {
            $('.select_data').on('change', function () {
                dataUser($(this));
            });

            $('.select_data').change();
        }
    });


    function getType(v) {
        if (document.querySelector(".etiquetas")) {
            switch (v) {
                case 'm':
                    let text_d = $('.mixed')[0].firstChild["data"];
                    return "(" + text_d + ")";
                case 'd':
                    let text_a = $('.digital')[0].firstChild["data"];
                    return "(" + text_a + ")";
                case 'a':
                    let text_m = $('.analog')[0].firstChild["data"];
                    return "(" + text_m + ")";
                case 'dm':
                    let text_dm = $('.demux')[0].firstChild["data"];
                    return "(" + text_dm + ")";
                default:
                    return $('.err')[0].firstChild["data"];
            }
        }
    }


})
(jQuery);

