/**
 * elementActuators.js
 * Created by PULSARINARA\d.monfort on 4/03/22.
 */

(function ($) {
    "use strict";
    $(document).ready(function () {

        let loadElements = function (item) {
            // console.log(item);
            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-element'),
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {
                    if ($(".sector-actuators").length) {

                        $(".sector-actuators").find('option').remove();
                        let actuators = data["actuators"];

                        $.each(actuators, function (name) {
                            // console.log(actuators[name]);
                            let actuator = actuators[name];
                            $(".sector-actuators").append('<option value="' + actuator["uid"] + '">' + actuator["name"] + '</option>');

                        });
                    }

                    if ($(".element-conditions").length) {

                        $(".element-conditions").find('option').remove();
                        let elements = data["sensors"];
                        $.each(elements, function (name) {
                            // console.log(elements[name]);
                            let element = elements[name];
                            $(".element-conditions").append('<option value="' + element["id"] + '">' + element["name"] + '</option>');

                        });
                    }

                    if ($(".address_list").length) {

                        $(".contacts_list").empty();
                        let contacts = data["contacts"];
                        $.each(contacts, function (name) {
                            // console.log(elements[name]);
                            let contact = contacts[name];
                            $(".contacts_list").append('<li value="' + contact["id"] + '"><input type="checkbox" id="contact_send%t%n' + contact["id"] + '" name="notification[contact_send][%t][%n][' + contact["id"] + ']"><label for="contact_send%t%n' + contact["id"] + '">&nbsp;&nbsp;  ' + contact["name"] + ' | ' + contact["tlf"] + '&nbsp; |&nbsp;  ' + contact["email"] + '</label></li>')
                        });
                    }


                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR elementActuators.js -> url-element', jqXHR);

                }
            });
        };

        if ($('.load-elements').length) {
            $('.load-elements').on('change', function () {
                // console.log($(this));
                loadElements($(this));

            })

            // Si se necesita hacer funcion condicional
            $('.load-elements').change();
        }


    });
})(jQuery);